import React, { useEffect, useRef, useState } from 'react'

// styles
import { OverlayFullScreen } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupShowInfoeKYC } from './index.styles'

// assets
import IcCloseWhite from '../../../../../../../../../../assets/images/IcCloseWhite'

// hook
import { useOnClickOutside } from '../../../../../../../../../../hook/useClickOutside'

const PopupShowInfoeKYC = ({ src, setSrc, closePopup, handleShowInfoKyc, item, listeKYC }) => {
    
    const refDropdown = useRef(null)

    useOnClickOutside(refDropdown, () => closePopup())

    window.addEventListener("keydown",(e)=>{
        if (e.key === "Escape"){
            closePopup()
        }
    })

  return (
    <OverlayFullScreen >
        <div className='wrap-inner-screen'>
            <WrapPopupShowInfoeKYC ref={refDropdown}>
                <IcCloseWhite className="ic-close" onClick={closePopup} />
                <div className='wrap-info-face'>
                    {
                        listeKYC?.length !== 0 ? (
                        <>
                            <img
                                src={src}
                                className='wrap-face-zoom'
                            />
                            <div className="face-img-item" >
                                <div className="img-item-check">
                                    <div className="list-item" >
                                    {listeKYC?.map((item, index) => (
                                        <img
                                        src={item}
                                        key={index}
                                        className={`wrap-face-item ${item === src ? "active" : ""}`}
                                        onClick={() => {
                                            setSrc(item)
                                        }}
                                        />
                                    ))}
                                </div>
                            </div>
                            </div>
                        </>
                        
                        ) : (
                        <img
                            // src={ImageZoomDefault}
                            className='wrap-face-default'
                        />
                        )
                    }
                </div>
                <div className='wrap-info-eKyc'>
                    {handleShowInfoKyc(item, true)}
                </div>
            </WrapPopupShowInfoeKYC>
        </div>
    </OverlayFullScreen>
  )
}

export default PopupShowInfoeKYC