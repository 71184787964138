import Axios from 'axios'
import { dataSwiftCodeBank } from '../../../constant/dataSwiftCodeBank'

// //host
import { hostCustomer } from '../../../constant/host'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'

// //help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import mapErrors from '../../../helper/mapErrors'
import { disableLoading, enableLoading, hideLoading, showLoading } from '../../loading/action'

//types
import * as types from './types'


const apigetListTransactionBankOfCustomer = `${hostCustomer}/transaction/filterById`
const apigetListTransactionFacepayOfCustomer = `${hostCustomer}/transaction/facepay/filter`

const apiExportTransactionBankOfCustomer = `${hostCustomer}/transaction/exportTransactionById`
const apiExportTransactionFacepayOfCustomer = `${hostCustomer}/transaction/exportFacepayTransactionByUid`

let headerDataRequest = {
    cid: "customer_web",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false
let lastQueryTimeApigetListTransactionBankOfCustomer = ""
let lastQueryTimeApigetListTransactionFacepayOfCustomer = ""

export const getListTransactionFacepayOfCustomer = (
    data = {
        uid: "",
        facepayTrxCode: "",
        supplier: ["*"],
        begin: "",
        end: "",
        isSuccess: true,
        isFail: true,
        limit: 15,
        skip: 0,
    },
    isScroll,
    callbackSuccess,
    callbackError
) => async (dispatch) => {
    
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data };

    // if (isCalling) return
    // isCalling = true
    lastQueryTimeApigetListTransactionFacepayOfCustomer = headerDataRequest.reqId

    !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER))

    try {
        const res = await Axios.post(apigetListTransactionFacepayOfCustomer, dataRequest)
        const { data } = res

        if(lastQueryTimeApigetListTransactionFacepayOfCustomer !==  dataRequest.reqId) return

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER,
                payload: {
                    list: data.data.list || [],
                    total: data.data.total,
                    totalSuccess: data.data.totalSuccess,
                    isScroll
                }
            })
            // isCalling = false
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR))
    }
    // isCalling = false
    !isScroll && setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER))
    }, 300);
}

export const resetListTransactionFacepayOfCustomer = () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER })
}


export const getListTransactionBankOfCustomer = (
    data = {
        bioId: "",
        facepayTrxCode: "",
        begin: "",
        end: "",
        isSuccess: true,
        isFail: true,
        limit: 15,
        skip: 0,
    },
    isScroll,
    callbackSuccess,
    callbackError
) => async (dispatch) => {
    
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = { ...headerDataRequest, data };
    
    // if (isCalling) return
    // isCalling = true

    lastQueryTimeApigetListTransactionBankOfCustomer = headerDataRequest.reqId 

    !isScroll && dispatch(showLoading(types.LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER))

    try {
        const res = await Axios.post(apigetListTransactionBankOfCustomer, dataRequest)
        const { data } = res
        
        if(lastQueryTimeApigetListTransactionBankOfCustomer !== dataRequest.reqId) return

        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_TRANSACTION_BANK_OF_CUSTOMER,
                payload: {
                    list: data.data.list || [],
                    total: data.data.total,
                    totalSuccess: data.data.totalSuccess,
                    isScroll
                }
            })
            // isCalling = false
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_TRANSACTION_BANK_OF_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_BANK_OF_CUSTOMER_ERR))
    }
    // isCalling = false
    !isScroll && setTimeout(() => {
        dispatch(hideLoading(types.LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER))
    }, 300);
}

export const resetListTransactionBankOfCustomer = () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_TRANSACTION_OF_CUSTOMER })
}

export const updateListTransactionOfCustomerCondsFilter = ({
    condFilters = {
        bioId: "",
        uid: "",
        facepayTrxCode: "",
        begin: "",
        end: "",
        isSuccess: true,
        isFail: true,
        limit: 15,
        skip: 0,
        supplier: ["*"],
    },
    isScroll = false,
    callbackSuccess
}) => async (dispatch) => {
    dispatch({
        type: types.UPDATE_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS,
        condFilters,
        isScroll
    })
    callbackSuccess && callbackSuccess()    
}

export const resetTransactionOfCustomerCondsFilter = () => async (dispatch) => {
    dispatch({ type: types.RESET_LIST_TRANSACTION_OF_CUSTOMER_CONDFILTERS })
}

export const exportTransactionBankOfCustomer = ({
    data = {
        bioId: "",
        facepayTrxCode: "",
        begin: "",
        end: "",
        isSuccess: true,
        isFail: true
    },
    callbackSuccess,
    callbackError
}) => async (dispatch) => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(enableLoading())

    try {
        const res = await Axios.post(apiExportTransactionBankOfCustomer, dataRequest, {
            responseType: 'blob',
        })
        const {data} = res
        autoDownloadFromBlob(data, 'transaction')
        callbackSuccess && callbackSuccess()
        
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_TRANSACTION_OF_CUSTOMER_ERR))
        callbackError && callbackError()
    }

    setTimeout(() => {
        dispatch(disableLoading())    
    }, 500);
    
}

export const exportTransactionFacepayOfCustomer = (
    data = {
        uid: "",
        swiftCode: [],
        facepayTrxCode: "",
        limit: 5,
        skip: 0,
        begin: "",
        end: "",
        isSuccess: true,
        isFail: true
    },
    callbackSuccess,
    callbackError
) => async dispatch => {
    headerDataRequest.reqId = `${new Date().getTime()}`
    const dataRequest = {...headerDataRequest, data}

    dispatch(enableLoading())

    try {
        const res = await Axios.post(apiExportTransactionFacepayOfCustomer, dataRequest, { responseType: "blob" })
        const { data } = res
        autoDownloadFromBlob(data, 'transaction')
        callbackSuccess && callbackSuccess()
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_TRANSACTION_FACEPAY_OF_CUSTOMER_ERR))
        callbackError && callbackError()
    }

    setTimeout(() => {
        dispatch(disableLoading())    
    }, 500);

}