import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { env, envServer, DEV_SV, BANK_ENV } from "../../../../constant/environmentTypes";

// constant
import * as path from "../../../../constant/path";

// styles
import { WrapBreadcrumbAndTool } from "./index.styles";

//image
import IcExportFile from "../../../../assets/images/IcExportFile";
import IcHistoryTransaction from "../../../../assets/images/IcHistoryTransaction";
import IcCreateNew from "../../../../assets/images/IcCreateNew";
import IcEditGray from "../../../../assets/images/IcEditGray";
import IcCheckWhite from "../../../../assets/images/IcCheckWhite";
import IcRefuseWhite from "../../../../assets/images/IcRefuseWhite";

// component
import Breadcrumb from "./Breadcrumb";
import Button from "./Button";
import ButtonCustom from "../../../Control/Button/index";
import FilterDevices from "./FilterDevices";
import CreateNewMerchant from "./CreateNewMerchant";
import Portal from "../../../Control/Portal";
import CreateNewBank from "./CreateNewBank";
import SettingBread from "./SettingBread";
import PopupExportFileDeviceV2 from "./PopupExportFileDeviceV2";
import PopupExportFileV2 from "./PopupExportFileV2";
import PopupWarningApproved from "../Page/Censorship/WaitingCensorshipDetail/PopupWarningApproved";
import dataDeviceStatus from "../../../../data/dataDeviceStatus";
import PopupRejectCensor from "../Page/Censorship/WaitingCensorshipDetail/PopupRejectCensor";
import PopupConfirmApprove from "../Page/Censorship/WaitingCensorshipDetail/PopupComfirmApprove";
import PopupConfirmRejectCensor from "../Page/Censorship/WaitingCensorshipDetail/PopupConfirmRejectCensor";
import { BANK, FACEPAYTERMINAL } from "../Page/TransactionV2";

// helper
import exportToCSV from "../../../../helper/exportExcel";
import { convertDateTime } from "../../../../helper/convertDateTime";
import checkStatusOnline from "../../../../helper/checkStatusOnline";

//action
import { updateWaitingCondsFilter } from "../../../../redux/censorship/listWaitingCensorship/action";
import { updateRejectCondsFilter } from "../../../../redux/censorship/listRejectProfile/action";
import { hidePopupExportTransactionBank, showPopupExportTransactionBank } from "../../../../redux/transactionV2/listTransactionBank/action";
import { hidePopupDropdownStoreName } from "../../../../redux/transactionV2/transactionOther/action";

const HistoryList = () => {
  const { facepayId } = useParams();
  const history = useHistory();

  const { transactionDetail } = useSelector((state) => ({
    transactionDetail:
      state.transactionReducer.transactionDetailReducer.details,
  }));

  const _handleOnClickTransaction = () => {
    history.push(`/${path.MAIN}/${path.TRANSACTION}`);
  };

  const _handleOnClickTransactionDetail = () => {
    history.push(`/${path.MAIN}/${path.TRANSACTION}/${facepayId}`);
  };

  return (
    <>
      <Breadcrumb
        arrPath={[
          { value: "Transaction", onClick: _handleOnClickTransaction },
          {
            value: "Chi tiết giao dịch",
            onClick: _handleOnClickTransactionDetail,
          },
          { value: "Lịch sử giao dịch" },
        ]}
      />
      {/* <div className='wrap-right-content'>
                <div className="wrap-id-trans">
                    <span className="id-name">Image Proof :</span>
                    <span className="id-value" title={transactionDetail.matchedTo || ""}>{transactionDetail.matchedTo || "Null"}</span>
                </div>
            </div> */}
    </>
  );
};

const TransDetails = () => {
  const { facepayId } = useParams();
  const history = useHistory();

  const { transactionDetail } = useSelector((state) => ({
    transactionDetail:
      state.transactionReducer.transactionDetailReducer.details,
  }));

  const _handleOnClickTransaction = () => {
    history.push(`/${path.MAIN}/${path.TRANSACTION}`);
  };

  const _handleOnClickUserHistory = () => {
    history.push(
      `/${path.MAIN}/${path.TRANSACTION}/${facepayId}/${path.HISTORY}/${transactionDetail?.matchedTo}`
    );
  };

  return (
    <>
      <Breadcrumb
        arrPath={[
          { value: "Transaction", onClick: _handleOnClickTransaction },
          { value: "Chi tiết giao dịch" },
        ]}
      />
      <div className="list-button">
        <Button
          Icon={<IcHistoryTransaction />}
          _handleOnClick={_handleOnClickUserHistory}
        >
          History Transaction
        </Button>
        {/* <Button Icon={<IcExportFile />}>Xuất file</Button> */}
      </div>
    </>
  );
};

const MerchantDetailsBread = () => {
  const history = useHistory();
  return (
    <>
      <Breadcrumb
        arrPath={[
          {
            value: "Merchant",
            onClick: () => history.push(`/${path.MAIN}/${path.MERCHANT}`),
          },
          {
            value: "Chi tiết Merchant",
          },
        ]}
      />
      <Button Icon={<IcExportFile />}>Xuất file</Button>
    </>
  );
};

const MerchantBread = () => {
  const [isShowModalAddMerChant, setIsShowModalAddMerChant] = useState(false);

  const _handleClickBtn = () => {
    setIsShowModalAddMerChant((pre) => !pre);
  };

  return (
    <>
      <Breadcrumb arrPath={[{ value: "Merchant" }]} />
      <div className="wrap-right-content">
        <Button Icon={<IcExportFile />}>Xuất file</Button>
        <ButtonCustom
          text="Tạo mới"
          iconBtn={<IcCreateNew />}
          className="btn-creteNew"
          onClick={_handleClickBtn}
        />
      </div>
      {isShowModalAddMerChant && (
        <Portal>
          <CreateNewMerchant
            onClickIconClose={_handleClickBtn}
            setIsShowModalAddMerChant={setIsShowModalAddMerChant}
          />
        </Portal>
      )}
    </>
  );
};

const BankDetailsBread = () => {
  const history = useHistory();
  return (
    <>
      <Breadcrumb
        arrPath={[
          {
            value: "Banks",
            onClick: () => history.push(`/${path.MAIN}/${path.BANK}`),
          },
          {
            value: "Chi tiết Banks",
          },
        ]}
      />
      <Button Icon={<IcExportFile />}>Xuất file</Button>
    </>
  );
};

const BankBread = () => {
  const [isShowModalAddBank, setIsShowModalAddBank] = useState(false);

  const _handleClickBtn = () => {
    setIsShowModalAddBank((pre) => !pre);
  };

  return (
    <>
      <Breadcrumb arrPath={[{ value: "Bank" }]} />
      <div className="wrap-right-content">
        <Button Icon={<IcExportFile />}>Xuất file</Button>
        <ButtonCustom
          text="Tạo mới"
          iconBtn={<IcCreateNew />}
          className="btn-creteNew"
          onClick={_handleClickBtn}
        />
      </div>
      {isShowModalAddBank && (
        <Portal>
          <CreateNewBank
            onClickIconClose={_handleClickBtn}
            setIsShowModalAddBank={setIsShowModalAddBank}
          />
        </Portal>
      )}
    </>
  );
};

const DeviceByAdminV2 = () => {

  const [isShowPopupExport, setIsShowPopupExport] = useState(false)

  return (
    <>
      <Breadcrumb arrPath={[{ value: "Thiết bị" }]} />
      <FilterDevices />
      <div className="wrap-right-content">
        <div className="wrap-right-export">
          <Button Icon={<IcExportFile />} _handleOnClick={() => setIsShowPopupExport(true)}>Xuất file</Button>
          {
            isShowPopupExport ? (
              <PopupExportFileDeviceV2 
                onClickClosePopup={() => setIsShowPopupExport(false)}
              />
            ) : ""
          }
        </div>
        <ButtonCustom
            text="Chỉnh sửa"
            iconBtn={<IcEditGray />}
            className='btn-edit disable'
            isDisable={true}
          />
      </div>
    </>
  );
};

const DeviceLocationByAdminTool = () => {
  const history = useHistory();

  const _handleClick = () => history.push(`/${path.MAIN}/${path.DEVICE}`);

  return (
    <>
      <Breadcrumb
        arrPath={[
          { value: "Thiết bị", onClick: _handleClick },
          { value: "Địa điểm thiết bị" },
        ]}
      />
    </>
  );
};

// const TransactionBread = () => {
//   const dispatch = useDispatch();

//   const [isShowModalExportFile, setIsShowModalExportFile] = useState(false);

//   const onRefreshTransactionList = () => {
//     dispatch(getTransactionCount());

//     const callbackSuccess = () =>
//       dispatch(
//         getListTransaction({ filterFrom: "", filterSkip: 0, filterSize: 20 })
//       );
//     dispatch(resetListTransaction(callbackSuccess));
//   };

//   const onExportFile = () => {
//     setIsShowModalExportFile((pre) => !pre);
//   };

//   return (
//     <>
//       {
//         env === BANK_ENV ?
//         <>
//           <Breadcrumb arrPath={[{ value: "Transactions" }]} />
//           <FilterTransaction />
//           <div className="wrap-right-content">
//             <Button Icon={<IcRefresh />} _handleOnClick={onRefreshTransactionList}>
//               {" "}
//               Refresh{" "}
//             </Button>
//             <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}>
//               {" "}
//               Xuất file{" "}
//             </Button>
//           </div>
//           {isShowModalExportFile && (
//             <Portal>
//               <PopupExportFile
//                 setIsShowModalExportFile={setIsShowModalExportFile}
//                 onClickIconClose={() => setIsShowModalExportFile(false)}
//               />
//             </Portal>
//           )}
//         </>
//         :
//         <div className="wrap-breadcumb-transaction-content">
//           <div className="wrap-top-content">
//             <Breadcrumb arrPath={[{ value: "Giao dịch" }]} />
//             {/* TODO: Export file */}

//           </div>
//           <div className="wrap-middle-content">
//             <FilterTransaction />
//           </div>
          
//           <div className="wrap-bottom-content">
//             check box
//           </div>
//         </div>
//       }
//     </>
//   );
// };

const TransactionV2Bread = ({ setNotHaveBoxShadow }) => {
  const dispatch = useDispatch()

  const { isShowPopupExportBank, reqType } = useSelector((state) => ({
    isShowPopupExportBank: state.transactionReducer.listTransactionBankReducer.isShowPopupExportBank,
    reqType:  state.transactionReducer.transactionOtherReducer.reqType
  }))

  useEffect(() => {
    setNotHaveBoxShadow(true);
    return () => {
      setNotHaveBoxShadow(false);
    };
  }, [])

  const handleOnClick = () => {
    dispatch(showPopupExportTransactionBank())
    dispatch(hidePopupDropdownStoreName())
  }

  return (
    <>
      <Breadcrumb arrPath={[{ value: "Giao dịch"}]} />
      <div className="wrap-right-content">
        <Button Icon={<IcExportFile />} _handleOnClick={handleOnClick}>
          {" "}
          Xuất file{" "}
        </Button>
        {
          isShowPopupExportBank && <PopupExportFileV2 onClickClosePopup={() => dispatch(hidePopupExportTransactionBank())} />
        }
      </div>
    </>
  )
}

// const CustomerBread = () => {
//   const dispatch = useDispatch();

//   const [isShowModalExportFile, setIsShowModalExportFile] = useState(false);

//   const onExportFile = () => {
//     setIsShowModalExportFile((pre) => !pre);
//   };

//   return (
//     <>
//       <Breadcrumb arrPath={[{ value: "Customer" }]} />
//       <FilterCustomer />
//       <div className="wrap-right-content">
//         <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}>
//           {" "}
//           Xuất file{" "}
//         </Button>
//       </div>
//       {isShowModalExportFile && (
//         <Portal>
//           <PopupExportFile
//             setIsShowModalExportFile={setIsShowModalExportFile}
//             onClickIconClose={() => setIsShowModalExportFile(false)}
//           />
//         </Portal>
//       )}
//     </>
//   );
// };

const CustomerV2Bread = () => {

  return (
    <>
      <Breadcrumb arrPath={[{ value: "Khách hàng" }]} />
    </>
  );
};

// const CustomerDetailBread = () => {
//   const history = useHistory();
//   const { custId } = useParams();

//   const _handleOnClickCustomer = () => {
//     history.push(`/${path.MAIN}/${path.CUSTOMER}`);
//   };

//   const onClickHistoryTransaction = () => {
//     history.push(`/${path.MAIN}/${path.CUSTOMER}/${custId}/${path.HISTORY}`);
//   };

//   const onExportFile = () => { };

//   return (
//     <>
//       <Breadcrumb
//         arrPath={[
//           { value: "Customer", onClick: _handleOnClickCustomer },
//           { value: "Chi tiết khách hàng" },
//         ]}
//       />
//       <div className="wrap-right-content">
//         <Button Icon={<IcEdit />} _handleOnClick={onClickHistoryTransaction}>
//           {" "}
//           Lịch sử giao dịch{" "}
//         </Button>
//         <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}>
//           {" "}
//           Xuất file{" "}
//         </Button>
//       </div>
//     </>
//   );
// };

// const CustomerHistoryBread = () => {
//   const history = useHistory();
//   const { custId } = useParams();

//   const _handleOnClickCustomer = () => {
//     history.push(`/${path.MAIN}/${path.CUSTOMER}`);
//   };

//   const _handleOnClickCustomerDetail = () => {
//     history.push(`/${path.MAIN}/${path.CUSTOMER}/${custId}`);
//   };

//   const onExportFile = () => { };

//   return (
//     <>
//       <Breadcrumb
//         arrPath={[
//           { value: "Customer", onClick: _handleOnClickCustomer },
//           {
//             value: "Chi tiết khách hàng",
//             onClick: _handleOnClickCustomerDetail,
//           },
//           { value: "Lịch sử giao dịch" },
//         ]}
//       />
//       <div className="wrap-right-content">
//         <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}>
//           {" "}
//           Xuất file{" "}
//         </Button>
//       </div>
//     </>
//   );
// };

const CensorshipBread = ({ setNotHaveBoxShadow }) => {
  useEffect(() => {
    setNotHaveBoxShadow(true);
    return () => {
      setNotHaveBoxShadow(false);
    };
  }, []);

  return (
    <>
      <div className="wrap-inner-breadcrumb">
        <Breadcrumb arrPath={[{ value: "Kiểm duyệt tài khoản" }]} />
      </div>
    </>
  );
};

const WaitingCensorshipDetailBread = () => {
  const btnRejectRef = useRef(null)
  const REJECT = 'REJECT'
  const CONFIRMREJECT = 'CONFIRMREJECT'

  const history = useHistory();

  const dispatch = useDispatch()
  const { reasonRejectUser, list } = useSelector((state) => ({
    reasonRejectUser: state.censorshipReducer.listReasonReducer.reasonRejectUser,
    list: state.censorshipReducer.listWaitingCensorshipReducer.list
  }))

  const { isCheckTypeSameFace } = useSelector(state => ({
    isCheckTypeSameFace: state.censorshipReducer.detailCensorshipReducer.isCheckTypeSameFace
  }))


  const [isShowPopupAproved, setIsShowPopupAproved] = useState(false);
  const [payload, setPayload] = useState({})
  // const [keyShowForm, setKeyShowForm] = useState('')
  const [keyShowForm, setKeyShowForm] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      key: "",
      rect: {},
    }
  )

  const _handleOnClickCensorship = () => {
    dispatch(updateWaitingCondsFilter({
      condFilters: {
        lastItem: "",
        limit: list.length > 0 ? list.length : 15
      }
    }))
    history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`);
  };

  const _handleClickBtnApprove = () => {
    if(keyShowForm.key === REJECT) return
    setIsShowPopupAproved(true)
  };

  const _handleClickBtnRefuse = () => {
    let rect = {}
    if (btnRejectRef.current) {
      rect = {
        top: btnRejectRef.current.getBoundingClientRect().top + window.scrollY + 4 + btnRejectRef.current.getBoundingClientRect().height,
        left: btnRejectRef.current.getBoundingClientRect().left + window.scrollX,
        btnWidth: btnRejectRef.current.getBoundingClientRect().width
      }
    }
    setKeyShowForm({ key: REJECT, rect });
  };

  const _handleSubmit = (uid, reasons, optionalReason) => {
    setKeyShowForm({ key: CONFIRMREJECT })
    let request = { uid: uid, reasons: reasons, optionalReason: optionalReason }
    setPayload(request)
  }

  useEffect(() => {
    // dispatch(getListReason())
  }, [])

  return (
    <>
      <Breadcrumb
        arrPath={[
          { value: "Chờ kiểm duyệt", onClick: _handleOnClickCensorship },
          { value: "Chi tiết hồ sơ" },
        ]}
      />
      <div className="wrap-right-content">
        <ButtonCustom
          text="Phê duyệt"
          iconBtn={<IcCheckWhite />}
          className="btn-approve"
          onClick={_handleClickBtnApprove}
        />
        <ButtonCustom
          text="Từ chối"
          iconBtn={<IcRefuseWhite />}
          className="btn-refuse"
          onClick={_handleClickBtnRefuse}
          ref={btnRejectRef}
        />
        {
          keyShowForm.key === REJECT && (
            <PopupRejectCensor
              rect={keyShowForm.rect}
              onClickClosePopup={() => setKeyShowForm({ key: "" })}
              _handleSubmit={_handleSubmit}
              reason={reasonRejectUser}
            />
          )
        }
        {
          keyShowForm.key === CONFIRMREJECT && (
            <Portal>
              <PopupConfirmRejectCensor onClickClosePopup={() => setKeyShowForm({ key: "" })}
                payload={payload}

              />
            </Portal>
          )
        }
        {
          isShowPopupAproved && (
            <Portal>
              <PopupConfirmApprove
                onClickClosePopup={() => setIsShowPopupAproved(false)}
              />
            </Portal>
          )
        }
        {
          isCheckTypeSameFace && (
            <Portal>
              <PopupWarningApproved />
            </Portal>
          )
        }
      </div>
    </>
  );
};

const RejectionProfileDetailBread = () => {
  const dispatch = useDispatch()
  const history = useHistory();

  const { censorshipId } = useParams();
  const { list } = useSelector((state) => ({
    list: state.censorshipReducer.listRejectProfileReducer.list
  }))

  const _handleOnClickCensorship = () => {
    dispatch(updateRejectCondsFilter({
      condFilters: {
        lastItem: "",
        limit: list.length > 0 ? list.length : 15
      }
    }))
    history.push(`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`);
  };

  return (
    <>
      <Breadcrumb
        arrPath={[
          { value: "Hồ sơ từ chối", onClick: _handleOnClickCensorship },
          { value: "Chi tiết hồ sơ" },
        ]}
      />
    </>
  );
};

const BreadcrumbAndTool = () => {

  const [notHaveBoxShadow, setNotHaveBoxShadow] = useState(false);

  return (
    <WrapBreadcrumbAndTool notHaveBoxShadow={notHaveBoxShadow}>
      <div className="wrap-breadcrumb-and-tool">
        <Switch>
          <Route path={`/${path.MAIN}/${path.ANALYTIC}`}>
            <Breadcrumb arrPath={[{ value: "Data Analytics" }]} />
            {/* <Button Icon= {<IcExportFile />}> Export File </Button> */}
          </Route>
          {/* <Route exact path={`/${path.MAIN}/${path.TRANSACTION}`}>
            <TransactionBread />
          </Route> */}
          <Route exact path={`/${path.MAIN}/${path.TRANSACTION}/:facepayId/${path.HISTORY}/:biometricId`}>
            <HistoryList />
          </Route>
          <Route path={`/${path.MAIN}/${path.TRANSACTION}/:facepayId`}>
            <TransDetails />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.MERCHANT}`}>
            <MerchantBread />
          </Route>
          <Route path={`/${path.MAIN}/${path.MERCHANT}/:merchantId`}>
            <MerchantDetailsBread />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.BANK}`}>
            <BankBread />
          </Route>
          <Route path={`/${path.MAIN}/${path.BANK}/:bankId`}>
            <BankDetailsBread />
          </Route>
          <Route path={`/${path.MAIN}/${path.PAYMENT_TERMINAL}`}>
            <Breadcrumb arrPath={[{ value: "Payment Terminal" }]} />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.DEVICE}`}>
            <DeviceByAdminV2 />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.DEVICE}/:deviceId/${path.LOCATION}`} >
            <DeviceLocationByAdminTool />
          </Route>
          <Route path={`/${path.MAIN}/${path.PERMISSION}`}>
            <SettingBread setNotHaveBoxShadow={setNotHaveBoxShadow} />
          </Route>
          <Route exact path={[`/${path.MAIN}/${path.CUSTOMER}`, `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_FACEPAY}`, `/${path.MAIN}/${path.CUSTOMER}/${path.CUSTOMER_BANK}`]}>
            <CustomerV2Bread />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.TRANSACTION}`}>
            <TransactionV2Bread setNotHaveBoxShadow={setNotHaveBoxShadow}/>
          </Route>
          {/* <Route exact path={`/${path.MAIN}/${path.CUSTOMER}/:custId`}>
            <CustomerDetailBread />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.CUSTOMER}/:custId/${path.HISTORY}`} >
            <CustomerHistoryBread />
          </Route> */}
          <Route exact path={[`/${path.MAIN}/${path.CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}`, `/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}`]}>
            <CensorshipBread setNotHaveBoxShadow={setNotHaveBoxShadow} />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.WAITING_CENSORSHIP}/:censorshipId`}>
            <WaitingCensorshipDetailBread />
          </Route>
          <Route exact path={`/${path.MAIN}/${path.CENSORSHIP}/${path.REJECTION_PROFILE}/:censorshipId`}>
            <RejectionProfileDetailBread />
          </Route>
        </Switch>
      </div>
    </WrapBreadcrumbAndTool>
  );
};

export default BreadcrumbAndTool;
