import React from 'react'

// component
import DropdownControl from '../../../../../../../../Control/DropdownControl'
import { dataSelectCheckCustomerFacepay } from '../../../../../../../../../data/dataSelectCheckbox'

const DropdownFilterCheckStatusCustomer = ({filterCondCheckStatus: { text, key }, setFilterCondCheckStatus, fitContent = true }) => {


    const _handleSetValueSelect = (val) => {
        setFilterCondCheckStatus(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelectCheckCustomerFacepay}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
            fitContent={fitContent}
        />
    )
}

export default DropdownFilterCheckStatusCustomer
