import React from 'react'

const IcTimeOutlineGray = (props) => {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_14351_7237)">
        <path d="M6.99984 12.8334C10.2215 12.8334 12.8332 10.2217 12.8332 7.00008C12.8332 3.77842 10.2215 1.16675 6.99984 1.16675C3.77818 1.16675 1.1665 3.77842 1.1665 7.00008C1.1665 10.2217 3.77818 12.8334 6.99984 12.8334Z" stroke="#777777" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 3.5V7L9.33333 8.16667" stroke="#777777" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_14351_7237">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default IcTimeOutlineGray