import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapPopupAttachNote, WrapPopupAttachNoteTransaction } from './index.styles'

//image
import IcClose from '../../../../../../../../assets/images/IcClose'
import IcBtnNote from '../../../../../../../../assets/images/IcBtnNote'
import IcCheckBgBlue from '../../../../../../../../assets/images/IcCheckBgBlue'
import IcCloseRedMini from '../../../../../../../../assets/images/IcCloseRedMini'

//component
import TextArea from '../../../../../../../Control/TextArea'

// action
import { customerMakeNoteBacklog, customerMakeNotePayment, getListCustomerNote, resetListCustomerNote, getListCustomerBankNote, resetListCustomerBankNote, customerMakeNoteBankPayment, customerMakeNoteBankBacklog } from '../../../../../../../../redux/customer/customerNote/action'

// helper
import { FACEPAY_PORTAL_LOGIN } from '../../../../../.././../../helper/localStore'
import { BACKLOG_NOTE, FAILED, PAYMENT_NOTE, POPUP_NOTE_BACKLOG, POPUP_NOTE_TRANSACTION_CUSTOMER, SUCCESS, FACEPAY, POPUP_NOTE_TRANSACTION } from '../../../../../../../../constant/keys'
import numberFormatToThousand from '../../../../../../../../helper/numberFormatToThousand'
import { convertDateTime } from '../../../../../../../../helper/convertDateTime'
import convertTypeBacklogToStr from '../../../../../../../../helper/convertTypeBacklogToStr'
import { FACEPAYTERMINAL } from '../../../../TransactionV2'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'


const adminUser = JSON.parse(localStorage.getItem(FACEPAY_PORTAL_LOGIN))

const PopupAttachNote = ({ data, onClickClosePopup, isShowContentPopup, isStylePopup }) => {
    const refPopup = useRef()
    const dispatch = useDispatch()
    
    const { facepayTransCode, status, transAmount, createdAt, type, info, id, imageProof, userId, bioID } = data

    const { details, reqType, reqTypeTransaction } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
        reqType: state.customerReducer.listCustomerReducer.reqType,
        reqTypeTransaction: state.transactionReducer.transactionOtherReducer.reqType
    }))

    const [valueNote, setValueNote] = useState("")

    const handleChange = (e) => {
        const value = e.target.value.trimStart()
        let isSpace = false
        for(let i = 0; i < value.length; i++){
            if(isSpace){
                if(value[i] === " "){
                    value = value.substring(0, i) + value.substring(i + 1)
                    i--
                }
                else isSpace = true
            }
            else isSpace = false
        }
        setValueNote(value)
    }

    const callbackSuccess = () => {
        if(!details) return

        if(reqType === FACEPAY) {
            const callbackSuccessListNote = () => {
                dispatch(getListCustomerNote({
                    adminId: adminUser?.uuid,
                    adminUserName: adminUser?.username,
                    uid: details?.id,
                    limit: 15,
                    skip: 0
                }))
            }
            dispatch(resetListCustomerNote(callbackSuccessListNote))
        }else{
            const callbackSuccessListBankNote = () => {
                dispatch(getListCustomerBankNote({
                    adminId: adminUser?.uuid,
                    adminUserName: adminUser?.username,
                    bioId: details?.id,
                    limit: 15,
                    skip: 0
                }))
            }
            dispatch(resetListCustomerBankNote(callbackSuccessListBankNote))
        }

        onClickClosePopup()
    }
    
    const _handleSubmit = () => {
        if(valueNote.length === 0) return

        switch (isShowContentPopup) {
            case POPUP_NOTE_TRANSACTION_CUSTOMER:
                if(reqType === FACEPAY){
                    dispatch(customerMakeNotePayment({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        uid: details?.id,
                        noteType: PAYMENT_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            state: status === 1 ? SUCCESS : FAILED,
                            fbTransactionCode: facepayTransCode,
                            amount: transAmount,
                            createAt: createdAt
                        }
                    }, callbackSuccess))
                }else{
                    dispatch(customerMakeNoteBankPayment({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        bioId: details?.id,
                        noteType: PAYMENT_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            state: status === 1 ? SUCCESS : FAILED,
                            fbTransactionCode: facepayTransCode,
                            amount: transAmount,
                            createAt: createdAt
                        }
                    }, callbackSuccess))
                }
                break;
            case POPUP_NOTE_BACKLOG:
                if(reqType === FACEPAY){
                    dispatch(customerMakeNoteBacklog({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        uid: details?.id,
                        noteType: BACKLOG_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            type: type,
                            info: convertTypeBacklogToStr(type, info).info || [],
                            createAt: createdAt,
                            idBacklog: id
                        }
                    }, callbackSuccess))
                }else{
                    dispatch(customerMakeNoteBankBacklog({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        bioId: details?.id,
                        noteType: BACKLOG_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            type: type,
                            info: convertTypeBacklogToStr(type, info).info || [],
                            createAt: createdAt,
                            idBacklog: id
                        }
                    }, callbackSuccess)) 
                }
                break;
            case POPUP_NOTE_TRANSACTION:
                if(reqTypeTransaction === FACEPAYTERMINAL){
                    dispatch(customerMakeNotePayment({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        uid: userId,
                        noteType: PAYMENT_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            state: status === 1 ? SUCCESS : FAILED,
                            fbTransactionCode: facepayTransCode,
                            amount: transAmount,
                            createAt: createdAt
                        }
                    }))
                }else{
                    dispatch(customerMakeNoteBankPayment({
                        adminId: adminUser.uuid,
                        adminUserName: adminUser.username,
                        bioId: bioID,
                        noteType: PAYMENT_NOTE,
                        message: valueNote,
                        noteAttachments: {
                            state: status === 1 ? SUCCESS : FAILED,
                            fbTransactionCode: facepayTransCode,
                            amount: transAmount,
                            createAt: createdAt
                        }
                    }))
                }
                onClickClosePopup()
                break;
        }
        onClickClosePopup()
    }

    useOnClickOutside(refPopup,()=>onClickClosePopup())
    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          onClickClosePopup();
        }
    });

    return (
        <>
        {
                isShowContentPopup === POPUP_NOTE_TRANSACTION ? (
                    <WrapPopupAttachNoteTransaction ref={refPopup}>
                        <div className="wrap-note-transaction">
                            <div className="note-title">
                                Ghi chú giao dịch
                                <IcClose className='icon-close-transaction' onClick={onClickClosePopup} />

                            </div>
                            <div className="note-content">
                                <TextArea
                                    placeholder="Nhập nội dung ghi chú tối đa 120 ký tự"
                                    onChange={handleChange}
                                    maxLength={120}
                                    value={valueNote}
                                />
                                <div className="wrap-btn-transaction">
                                {/* <p className="note">Tối đa 120 ký tự</p> */}
                                <IcBtnNote onClick={_handleSubmit} className="btn-note" />
                                </div>
                            </div>
                        </div>
                        
                    </WrapPopupAttachNoteTransaction>
                ) : (
            <WrapPopupAttachNote ref={refPopup} isStylePopup={isStylePopup}>
                <IcClose className="icon-close" onClick={onClickClosePopup} />
                <p className="title-popup">Đính kèm ghi chú</p>
                    {
                        isShowContentPopup === POPUP_NOTE_TRANSACTION_CUSTOMER && (
                            <div className='wrap-note-transaction-customer'>
                                <div className={`block-content ${status === 1 ? "success" : "fail"}`}>
                                <div className={status === 1 ? "wrap-content success" : "wrap-content fail"}>
                                    <div className="content">
                                        {status === 1 ? <IcCheckBgBlue className="icon" /> : <IcCloseRedMini className="icon" />}
                                        <p className="value bold">{facepayTransCode || "-"}</p>
                                    </div>
                                    <p className="value">Lịch sử giao dịch</p>
                                </div>
                                <div className="wrap-content right">
                                    <p className="value bold">{transAmount ? numberFormatToThousand(transAmount) : "-"}</p>
                                    <p className="value">{createdAt ? convertDateTime(createdAt, "HH:mm - DD/MM/YYYY") : "-"}</p>
                                </div>
                                </div>
                                <div className="wrap-text-note">
                                    <TextArea
                                        placeholder="Nhập nội dung ghi chú tối đa 120 ký tự"
                                        onChange={handleChange}
                                        maxLength={120}
                                        value={valueNote}
                                    />
                                </div>
                                <div className="wrap-btn">
                                    {/* <p className="note">Tối đa 120 ký tự</p> */}
                                    <IcBtnNote onClick={_handleSubmit} className="btn-note" />
                                </div>
                            </div>
                        )
                    }
                    {
                        isShowContentPopup === POPUP_NOTE_BACKLOG && (
                            <>
                                <div className={`block-content-backlog ${convertTypeBacklogToStr(type, info).style}`}>
                                    <div className={convertTypeBacklogToStr(type, info).style}>
                                        <div className='wrap-content'>
                                            {convertTypeBacklogToStr(type, info, 'content').str}
                                        </div>
                                        <div className="wrap-content backlog-time-value">
                                            <p className="value-backlog">Backlog tài khoản</p>
                                            <p className="value">{createdAt ? convertDateTime(createdAt, "HH:mm - DD/MM/YYYY") : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrap-text-note">
                                    <TextArea
                                        placeholder="Nhập nội dung ghi chú tối đa 120 ký tự"
                                        onChange={handleChange}
                                        maxLength={120}
                                        value={valueNote}
                                    />
                                </div>
                                <div className="wrap-btn">
                                    {/* <p className="note">Tối đa 120 ký tự</p> */}
                                    <IcBtnNote onClick={_handleSubmit} className="btn-note" />
                                </div>
                            </>
                        )
                    }
            </WrapPopupAttachNote>
            )
            }
        
        </>
        
    )
}

export default PopupAttachNote
