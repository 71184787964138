import * as types from './types'

import { dataSelectionCustomer } from '../../../components/Main/Content/Page/CustomerV2/CustomerList/BankCustomerList/FilterBankBlock/DropdownFilterCustomer'
import { dataSelectCheckCustomerFacepay } from '../../../data/dataSelectCheckbox'

export const listProviders = [
    {
        id: 1,
        title: "TPBank",
        type: 'TPB',
        isSelect: true
    },
    {
        id: 2,
        title: "OCB",
        type: 'OCB',
        isSelect: true
    },
    {
        id: 3,
        title: "Vietinbank",
        type: 'CTG',
        isSelect: true
    },
    {
        id: 4,
        title: "SmartPay",
        type: 'SMARTPAY',
        isSelect: true
    },
]

const intitialState = {
    listCustomerBank: [],
    listCustomerFacepay: [],

    condFiltersBank: {
        limit: 15,
        skipItem: 0,
        phone: "",
        providers: listProviders,
    },

    condFiltersFacepay: {
        optionSelected: dataSelectionCustomer[0],
        phone: "",
        profileId: "",
        fullname: "",
        lastItem: "",
        limit: 15,
        status: dataSelectCheckCustomerFacepay[0]
    },

    reqType: 'FACEPAY',
    isScroll: false,
    isCheckList: false,
    total: 0,
}
const listCustomerReducer = (state = intitialState, action) => {
    switch (action.type) {

        // BANK
        case types.UPDATE_BANK_CUSTOMER_CONDFILTERS:
            return {
                ...state,
                condFiltersBank: {
                    ...state.condFiltersBank,
                    ...action.condFiltersBank,
                },
                isScroll: action.isScroll
            }

        case types.RESET_BANK_CUSTOMER_CONDFILTERS:
            return {
                ...state,
                condFiltersBank: intitialState.condFiltersBank,
                isScroll: false
            }
        case types.GET_LIST_CUSTOMER_BANK:
            return {
                ...state,
                ...action.payload,
                listCustomerBank: action.payload.isScroll
                    ? [...state.listCustomerBank, ...action.payload.listCustomerBank]
                    : action.payload.listCustomerBank,
                isCheckList : intitialState.listCustomerBank.length === 0 ? true : false
            }
        case types.RESET_LIST_CUSTOMER_BANK:
            return {
                ...state,
                listCustomerBank: [],
                isCheckList: false,
            }


        // FACEPAY
        case types.GET_LIST_CUSTOMER_FACEPAY:
            return {
                ...state,
                ...action.payload,
                listCustomerFacepay: action.payload.isScroll
                    ? [...state.listCustomerFacepay, ...action.payload.listCustomerFacepay]
                    : action.payload.listCustomerFacepay,
                isCheckList : intitialState.listCustomerFacepay.length === 0 ? true : false
            }
        case types.RESET_LIST_CUSTOMER_FACEPAY:
            return {
                ...state,
                listCustomerFacepay: [],
                isCheckList: false
            }
        case types.UPDATE_FACEPAY_CUSTOMER_CONDFILTERS:
            return {
                ...state,
                condFiltersFacepay: {
                    ...state.condFiltersFacepay,
                    ...action.condFiltersFacepay,
                },
                isScroll: action.isScroll,
            }

        case types.RESET_FACEPAY_CUSTOMER_CONDFILTERS:
            return {
                ...state,
                condFiltersFacepay: intitialState.condFiltersFacepay,
                isScroll: false,
            }
        
        // reqType
        case types.GET_LIST_CUSTOMER_CHANGE_TAB:
            return {
                ...state,
                reqType: action.reqType
            }
        default:
            return state

    }
}
export default listCustomerReducer