import { TPBANK, OCB, VIETTINBANK, PVCOMBANK, SMARTPAY, FACEPAY } from "../constant/supplierKey"
import { TERMINAL, FACE_POS } from "../constant/sourceKey";

// data error
import { dataErrorOCB } from "../dataErrorBank/dataErrorOCB";
import { dataErrorPVC } from "../dataErrorBank/dataErrorPVC";
import { dataErrorSmartpay } from "../dataErrorBank/dataErrorSmartpay";
import { dataErrorTPB } from "../dataErrorBank/dataErrorTPB";
import { dataErrorVTB } from "../dataErrorBank/dataErrorVTB";

import { dataErrorFacepay } from "../dataErrorBank/dataErrorFacepay";
import { dataErrorFacePos } from "../dataErrorBank/dataErrorFacePos";

import { dataErrorDeposit } from "../dataErrorDeposit/dataErrorDeposit";

export const mapErrorTransaction = ( data = {
    supplier: "",
    source: "",
    paymentCode: "",
    paymentDescription: "",
    isWallet: false
}) => {
    const { supplier, source, paymentCode, paymentDescription, isWallet } = data
    if(isWallet){
        return dataErrorDeposit[paymentCode]?.text || paymentDescription
    }
    switch (supplier) {
        case FACEPAY:{
            switch (source) {
                case TERMINAL:{
                    return dataErrorFacepay[paymentCode]?.text || paymentDescription
                }
                    break;
                case FACE_POS:{
                    return dataErrorFacePos[paymentCode]?.text || paymentDescription
                }
                    break;

                default:{
                    return dataErrorFacepay[paymentCode]?.text || paymentDescription
                }
                    break;
            }
        }
            break;
        case SMARTPAY:{
            return dataErrorSmartpay[paymentCode]?.text || paymentDescription
        }
            break;
        case TPBANK:{
            return dataErrorTPB[paymentCode]?.text || paymentDescription
        }
            break;
        case OCB:{
            return dataErrorOCB[paymentCode]?.text || paymentDescription
        }
            break;
        case VIETTINBANK:{
            return dataErrorVTB[paymentCode]?.text || paymentDescription
        }
            break;
        case PVCOMBANK:{
            return dataErrorPVC[paymentCode]?.text || paymentDescription
        }
            break;
    
        default:
            break;
    }                           
}