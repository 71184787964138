import styled from 'styled-components'

const WrapTransHistoryDetailsItem = styled.div`
    width: 760px;
    min-height: 516px;
    background: #F7F7F7;
    border-radius: 4px;
    /* padding: 12px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .icon-close{
        position: absolute;
        top: -25px;
        right: 5px;
        cursor: pointer;
    }
    .wrap-refund{
        display: flex;
        align-items: center;
        gap: 7px;
        padding: 8px 12px 0 12px;
        .icon{
            display: flex;
            align-items: center;
        }
        .refund{
            display: flex;
            align-items: center;
            gap: 2px;
            font-family: 'Open Sans';
            font-style: normal;
            p{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: #313131;
            }
            .refund-time{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #777777;
            }
        }
    }
    .wrap-status{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #EFF9FF;
        height: 56px;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        &.fail{
            background: #FFEEEE;
        }
        .content{
            display: flex;
            align-items: center;
            font-family: 'Open Sans';
            font-style: normal;
            .refund-done{
                display: flex;
                align-items: center;
                gap: 4px;
                p{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #33ADFF;
                }
            }
            .facepay-id{                
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                color: #313131;
                padding-left: 8px;
            }
            .time{
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                color: #777777;
                padding-right: 10px;
            }
            .btn-refund{
                width: 102px;
                height: 32px;
                background: #33ADFF;
                border-radius: 4px;
            }
            .disable{
                background: #EAEAEA;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13.5px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #B7B7B7;
            }
        }
    }
    .wrap-reason{
        background: #F45C5C;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        min-height: 32px;
        padding: 5px 10px;
        width: calc(100% - 20px);
        display: grid;
        grid-template-columns: 16px auto;
        grid-column-gap: 4px;
        align-items: center;
        justify-content: center;
        .errro-text{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px;
            color: #FFFFFF;
        }
    }
    .wrap-info-transaction{
        display: flex;
        justify-content: space-between;
        padding: 8px 12px 12px 12px;
        box-sizing: border-box;
        .info-transaction{
            width: 516px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .block-info{
                background: #FFFFFF;
                border-radius: 4px;
                padding: 7px 10px 10px 10px;
                box-sizing: border-box;
                margin-bottom: 8px;
                .wrap-info{
                    display: grid;
                    grid-template-columns: 220px 1fr;
                    grid-gap: 10px;                
                }
            }
            .title{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
                margin-bottom: 8px;
            }            
            .block-info-customer{
                background: #FFFFFF;
                border-radius: 4px;
                padding: 7px 10px 20px 10px;
                box-sizing: border-box;
                .wrap-info-customer{
                    display: grid;
                    grid-template-columns: 220px 1fr;
                    grid-template-rows: 35px 35px ;
                    grid-gap: 18px 10px;
                }
                .empty-container{

                    p{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: -0.02em;
                        color: #B7B7B7;
                    }
                    .icon{
                        width: 72px;
                        height: 72px;
                        margin-bottom: 0;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .info{
                display: flex;
                align-items: center;
                .icon{
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    object-fit: cover;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    padding-left: 9px;
                    font-style: normal;
                    overflow: hidden;
                    .value{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13.5px;
                        line-height: 18px;
                        color: #313131;
                        margin-bottom: 2px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .text{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 15px;
                        color: #777777;
                    }
                }
            }
        }
        .info-face-registration{
            width: 214px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 11px 12px 13px 12px;
            box-sizing: border-box;
            .wrap-value-trans{
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: flex-end;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #313131;
                position: relative;

            }
            .wrap-value{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                color: #313131;
                position: relative;
                &-note{
                    display: flex;
                    gap: 6px;
                }
                &-popup{
                    position: absolute;
                    z-index: 10;
                    top: 20px;
                    right: -12px;
                }
                .value-bioId{
                    font-weight: 600;
                    padding-left: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 110px;
                }
                .ic-note{
                    cursor: pointer;
                    padding-right: 3px;
                }
                .note{
                    cursor: pointer;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    color: #313131;
                }
            }
            .face-registration {
                margin: 10px 0 8px 0;
                display: flex;
                flex-direction: column;
                .wrap-media {
                    position: relative;
                    width: 190px;
                    height: 188px;
                    img {
                        border-radius: 4px;
                        display: block;
                        width: 100%;
                        object-fit: cover;
                        box-sizing: border-box;
                        height: 180px;
                    }
                    .wrapper{
                        width: 100%;
                        height: 100%;
                        background: #F7F7F7;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;   
                        pointer-events: auto;
                        cursor: auto;
                    }
                    &.face-img {                           
                        img {
                            border: 2px solid #33ADFF;
                        }
                        .tools {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: 100%;
                            padding: 0 12px 11px 11px;
                            box-sizing: border-box;
                            .accuracy {
                                font-family: "Open Sans";
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 20px;
                                letter-spacing: -0.01em;
                                color: #33ADFF;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    &.recog-video {
                        margin-top: 8px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.4);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        
    }

`
export { WrapTransHistoryDetailsItem }