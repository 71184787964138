import moment from 'moment';
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
// styles
import { WrapDatePickerItem, WrapSelectDate } from './index.styles';

// image
import IcArrowNext from '../../../assets/images/IcArrowNext'
import IcArrowPrev from '../../../assets/images/IcArrowPrev';
import { getMonth, getYear } from 'date-fns';

const weekDays = {
    'Monday': 'T2',
    'Tuesday': 'T3',
    'Wednesday': 'T4',
    'Thursday': 'T5',
    'Friday': 'T6',
    'Saturday': 'T7',
    'Sunday': 'CN',
}

const SelectDate = forwardRef(({ textEmpty, value, onClick }, ref) => {
    
    return (
        <WrapSelectDate className={value ? 'have-value' : 'empty'} onClick={onClick} ref={ref}>
            {value || textEmpty}
        </WrapSelectDate>
    )
});

const DatePickerItem = ({ textEmpty, ...props }) => {

    const range = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
    }

    const years = range(1990, getYear(new Date()) + 1)

    const months = [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ];

    return (
        <WrapDatePickerItem >
            <DatePicker 
                customInput={<SelectDate textEmpty={textEmpty} />}
                dateFormat="HH:mm - dd/MM/yy"
                showTimeSelect
                showPopperArrow={false}
                timeFormat="HH:mm"
                timeIntervals={60}
                popperPlacement="bottom"
                formatWeekDay={formattedDate => weekDays[formattedDate]}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    changeYear,
                    changeMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <>
                        <div className='header-calendar'>
                            <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                aria-label="Previous Month">
                                <IcArrowPrev className='back-reverse'/>
                            </button>
                            <div className="title-header">Tháng {moment(date).format("M/YYYY")}</div>
                            <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                aria-label="Next Month">
                                <IcArrowNext className="reverse" />
                            </button>
                        </div>
                        <div
                            className='header-month-year'
                        >
                        <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>
                        <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>
                        </div>
                    </>
                )}
                {...props}
            />
        </WrapDatePickerItem>
    )
}

export default DatePickerItem
