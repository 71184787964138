import React, { useEffect, useMemo, useReducer, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapFilterBankBlock, WrapFilterSearch, WrapDropDown } from './index.styles'

//image
import IcUnCheckBox from "../../../../../../../../assets/images/IcUnCheckBox";
import IcCheckBoxActive from "../../../../../../../../assets/images/IcCheckBoxActive";
import IcExportFileBlue from "../../../../../../../../assets/images/IcExportFileBlue";

//helper
import removeAccent from "../../../../../../../../helper/removeAccent";
import trimStartAndStripStr from "../../../../../../../../helper/trimStartAndStripStr";
// import DropdownFilterCustomer, { FULLNAME_ID, PROFILE_ID, PHONE_NUMBER } from "./DropdownFilterCustomer";

//action
import { updateBankCustomerCondsFilter } from "../../../../../../../../redux/customer/listCustomer/actionV2";

//component
// import { InputFilter } from "../../../../../../../Control/FilterControl/index.styles";
// import FilterControl from "../../../../../../../Control/FilterControl";
// import CheckBoxControl from "../../../../../../../Control/CheckBoxControl";
import InputDropdownBank from "./InputDropDownBank";
import InputSearch from "../../../../../../../Control/InputSearch";
import { listProviders } from "../../../../../../../../redux/customer/listCustomer/listCustomerReducerV2";
import { LOADING_GET_LIST_CUSTOMER } from "../../../../../../../../redux/customer/listCustomer/types";
import resetListFilterBank from "../../../../../../../../helper/resetListFilterBank";
import { updatecondCheckboxAll } from "../../../../../../../../redux/transactionV2/transactionOther/action";
import { BANK_CUSTOMER } from "../../../../../../../../constant/keys";

export const iconCheckBox = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};

export default function FilterBankBlock() {

  const dispatch = useDispatch()
  // const inputSearchRef = useRef(null)

  const { condFiltersBank, isCheckList, listCustomerBank, isTypeLoading, condCheckboxAll } = useSelector(state => ({
    condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,
    listCustomerBank: state.customerReducer.listCustomerReducer.listCustomerBank,

    isCheckList: state.customerReducer.listCustomerReducer.isCheckList,

    isTypeLoading: state.loading[LOADING_GET_LIST_CUSTOMER],

    condCheckboxAll: state.transactionReducer.transactionOtherReducer.condCheckboxAll,
  }))

  const [arrProviders, setArrProviders] = useState([])
  const [valueSearch, setValueSearch] = useState(condFiltersBank.phone || "");

  useEffect(() => {
    setValueSearch(condFiltersBank.phone || "")
  },[condFiltersBank])

  const _handleSearch = () => {
    dispatch(updateBankCustomerCondsFilter({
      condFiltersBank: {
        limit: 15,
        skipItem: 0,
        phone: valueSearch,
        providers: condFiltersBank.providers
      }
    }))
  }

  const handleSelectCheckbox = (checkboxSelected) => {
    if(!condFiltersBank.providers) return
    let checkboxCollection = condFiltersBank.providers.map((item, index) => {
        if(index !== checkboxSelected){
            return {
                ...item
            }
        }else {
            return {
                ...item,
                isSelect: !item.isSelect
            }
        }
    })
    dispatch(updateBankCustomerCondsFilter({
      condFiltersBank: {
        ...condFiltersBank,
        providers: checkboxCollection
      }
    }))
    dispatch(updatecondCheckboxAll({
      ...condCheckboxAll,
      isSelect: listProviders.length === checkboxCollection.filter((item) => item.isSelect).length ? true : false
    }))
  }

  useEffect(() => {
    if (listCustomerBank.length === 0 && !isCheckList) {
      setValueSearch('')
    }
  }, [listCustomerBank])

  return (
    <WrapFilterBankBlock>
      <WrapFilterSearch>
        <InputSearch
          className="filter-search"
          value={valueSearch}
          type="text"
          placeholder="Nhập số điện thoại..."
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              _handleSearch();
            }
          }}
          maxLength={10}
          handleSearch={_handleSearch}
          onChange={(e) => setValueSearch(removeAccent(trimStartAndStripStr(e.target.value)))}
        />
        {/* <div title="Xuất file dữ liệu">
          <IcExportFileBlue className="icon-export" />
        </div> */}
      </WrapFilterSearch>
      <WrapDropDown>
        <InputDropdownBank
          reqType={BANK_CUSTOMER}
          iconCheckBox={iconCheckBox}
          listDropdown={condFiltersBank.providers}
          listChecked={condFiltersBank.providers.filter((item) => item.isSelect).map((item) => item.title)}
          handleCheckItem={handleSelectCheckbox}
        />
      </WrapDropDown>
    </WrapFilterBankBlock>
  )
}
