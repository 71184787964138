import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { WrapFilterTransaction,
    WrapFilterTransactionSearch,
    WrapFilterTransactionCheckbox, 
    FilterControlTime,
    WrapFilterTransactionControl,
    FilterControlStore
} from './index.styles'

// images
import IcArrowDownGray from '../../../../../../../assets/images/IcArrowDownGray'
import IcCalendarActive from '../../../../../../../assets/images/IcCalendarActive'
import IcRefresh from '../../../../../../../assets/images/IcRefresh'
import IcUnCheckBox from '../../../../../../../assets/images/IcUnCheckBox'
import IcCheckBoxActive from '../../../../../../../assets/images/IcCheckBoxActive'
import IcCheckBoxDisabled from '../../../../../../../assets/images/IcCheckBoxDisabled'
import IcStoreActive from '../../../../../../../assets/images/IcStoreActive'

// component
import Button from '../../../../../../Control/Button'
import FilterControl from '../../../../../../Control/FilterControl'
import DropdownFilterTime from './DropdownFilterTime'
import CheckBoxControl from '../../../../../../Control/CheckBoxControl'
import DropdownFilterBank from './DropdownFilterBank'
import InputDropdownBank from '../../../CustomerV2/CustomerList/BankCustomerList/FilterBankBlock/InputDropDownBank'
import DropdownFilterStore from './DropdownFilterStore'
import DatePickerTransaction from './DatePickerTransaction'
import InputSearchTransaction from './InputSearchTransaction'

// contanst
import { BANK, BANK_TX_ID, FACEPAY_TX_ID, MERCHANT_TX_ID, PHONE_NUMBER, PROFILE_ID, VOUCHER } from '../../../../../../../constant/keys'
import { dataSwiftCodeBank } from '../../../../../../../constant/dataSwiftCodeBank'

// redux
import { resetTransactionBankCondfilters, updateListTransactionBank } from '../../../../../../../redux/transactionV2/listTransactionBank/action'
import { currentDay, startDay } from '../../../../../../../redux/transactionV2/listTransactionBank/listTransactionBankReducer'
import { getListStoreName, hidePopupDropdownStoreName, resetCondFiltersStoreName, resetIsCheckSearchStore, showPopupDropdownStoreName, updatecondCheckboxAll } from '../../../../../../../redux/transactionV2/transactionOther/action'
import { dataSelectCheckbox } from '../../../../../../../data/dataSelectCheckbox'

const iconCheckBox = {
    icon: IcUnCheckBox,
    iconActive: IcCheckBoxActive,
    iconDisabled: IcCheckBoxDisabled
};

const FilterTransactionBank = () => {

    const dispatch = useDispatch()

    const { condFiltersTransactionBank, condFiltersStoreName, isShowDropdownListStore, listStoreName, condCheckboxAll } = useSelector((state) => ({
        // bank
        condFiltersTransactionBank: state.transactionReducer.listTransactionBankReducer.condFiltersTransactionBank,
        // storename
        condFiltersStoreName: state.transactionReducer.transactionOtherReducer.condFiltersStoreName,
        isShowDropdownListStore: state.transactionReducer.transactionOtherReducer.isShowDropdownListStore,
        listStoreName: state.transactionReducer.transactionOtherReducer.listStoreName,

        condCheckboxAll: state.transactionReducer.transactionOtherReducer.condCheckboxAll,

    }))
    const [valueSearch, setValueSearch] = useState('')

    const [filterCondTransaction, setFilterCondTransaction] = useState(condFiltersTransactionBank.OptionSelect)
    const [filterCondTransactionTime, setFilterCondTransactionTime] = useState(condFiltersTransactionBank.OptionSelectTime)

    
    const [data, setData] = useReducer(
        (state, action) => ({...state, ...action}), 
        {
            limit: condFiltersTransactionBank.limit,
            lastItemId: condFiltersTransactionBank.lastItemId,
            bankTrxCode: condFiltersTransactionBank.bankTrxCode,
            facepayTrxCode: condFiltersTransactionBank.facepayTrxCode,
            merchantTrxCode: condFiltersTransactionBank.merchantTrxCode,
            phoneNumber: condFiltersTransactionBank.phoneNumber,
            profileId: condFiltersTransactionBank.profileId,
            code: condFiltersTransactionBank.code,
            storeId: condFiltersTransactionBank.storeId,
            OptionSelectCheckbox: condFiltersTransactionBank.OptionSelectCheckbox,
            supplier: condFiltersTransactionBank.supplier,
            begin: condFiltersTransactionBank.begin,
            end: condFiltersTransactionBank.end,
        }
    )

    const _handleChangeOptionFilter = (filterOption) => {
        setFilterCondTransaction(filterOption)
        setValueSearch("")
    }

    const _handleChangeOptionFilterTime = (filterOption) => {
        setFilterCondTransactionTime(filterOption)
    }

    const _handleSearch = () => {
        const { key } = filterCondTransaction
        let bankTrxCode = ""
        let facepayTrxCode = ""
        let merchantTrxCode = ""
        let profileId = ""
        let phoneNumber = ""
        let code = ""
        switch (key) {
          case BANK_TX_ID: {
            bankTrxCode = valueSearch
            break;
          }
          case FACEPAY_TX_ID: {
            facepayTrxCode = valueSearch
            break;
          }
          case MERCHANT_TX_ID: {
            merchantTrxCode = valueSearch
            break;
          }
          case PROFILE_ID: {
            profileId = valueSearch
            break;
          }
          case PHONE_NUMBER: {
            phoneNumber = valueSearch
            break;
          }
          case VOUCHER: {
            code = valueSearch
            break;
          }
          default:
            return;
        }
        dispatch(updateListTransactionBank({
            ...condFiltersTransactionBank,
            bankTrxCode,
            facepayTrxCode,
            merchantTrxCode,
            profileId,
            phoneNumber,
            code
        }))
    }

    const dropdownFilterBank = useMemo(() => (
        <DropdownFilterBank
            reqType={BANK}
            filterCond={filterCondTransaction}
            setFilterCond={_handleChangeOptionFilter}
        />
    ),[filterCondTransaction])

    const dropdownFilterTime = useMemo(() => (
        <DropdownFilterTime 
            filterCond={filterCondTransactionTime}
            setFilterCond={_handleChangeOptionFilterTime}
        />
    ),[filterCondTransactionTime])

    const dropdownFilterStore = useMemo(() => (
        <DropdownFilterStore
            reqType={BANK}
            listStoreName={listStoreName}
            iconCheckBox={iconCheckBox}
            onClosePopup={() => dispatch(hidePopupDropdownStoreName())}
        />
    ),[listStoreName])

    const filterComponent = useMemo(() => (
        <InputSearchTransaction 
            filterCondTransaction={filterCondTransaction}
            valueSearch={valueSearch}
            setValueSearch={setValueSearch}
        />
    ),[filterCondTransaction, _handleSearch])

    const filterComponentTime = useMemo(() => (
        <DatePickerTransaction
            filterCondTransactionTime={filterCondTransactionTime}
            setFilterCondTransactionTime={setFilterCondTransactionTime}
            data={data}
            setData={setData}
            condFiltersTransaction={condFiltersTransactionBank}
            reqType={BANK}
        />
    ),[filterCondTransactionTime, data.begin, data.end])
    
    const handleSelectCheckboxBank = (checkboxSelected) => {
        if(!condFiltersTransactionBank.supplier) return
        let checkboxCollection = condFiltersTransactionBank.supplier.map((item, index) => {
            if(index !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        
        dispatch(updateListTransactionBank({
            ...condFiltersTransactionBank,
            supplier: checkboxCollection
        }))
        dispatch(updatecondCheckboxAll({
            ...condCheckboxAll,
            isSelect: dataSwiftCodeBank.length === checkboxCollection.filter((item) => item.isSelect).length ? true : false
        }))
    }

    const handleSelectCheckbox = (checkboxSelected) => {
        if(!condFiltersTransactionBank.OptionSelectCheckbox) return
        let checkboxCollection = condFiltersTransactionBank.OptionSelectCheckbox.map((item) => {
            if(item.id !== checkboxSelected){
                return {
                    ...item
                }
            }else {
                return {
                    ...item,
                    isSelect: !item.isSelect
                }
            }
        })
        dispatch(updateListTransactionBank({
            ...condFiltersTransactionBank,
            OptionSelectCheckbox: checkboxCollection
        }))

    }

    const handleRefresh = () => {
        const callbackSuccess = () => {
            dispatch(getListStoreName({
                name: [""]
              }))
        }
        setFilterCondTransactionTime(condFiltersTransactionBank.OptionSelectTime)
        setData({
            begin: startDay,
            end: currentDay,
        })
        dispatch(updatecondCheckboxAll({
            ...condCheckboxAll,
            isSelect: true
        }))
        dispatch(resetTransactionBankCondfilters())
        dispatch(resetIsCheckSearchStore())
        dispatch(resetCondFiltersStoreName(callbackSuccess))
        setValueSearch("")
    }

    return (
        <WrapFilterTransaction>
            <WrapFilterTransactionSearch>
                <WrapFilterTransactionControl>
                    <FilterControl
                        className='input-transaction'
                        dropdownFilter={dropdownFilterBank}
                        filterComponent={filterComponent}
                        handleSearch={_handleSearch}
                        value={valueSearch}
                        isShowSubmitBtn={true}
                    />
                    <FilterControlTime>
                        <IcCalendarActive />
                        {dropdownFilterTime}
                        {filterComponentTime}
                    </FilterControlTime>
                    <FilterControlStore>
                        <div className="store-title" >
                            <div className="title">
                                <IcStoreActive /> 
                                <p className="value-title">Cửa hàng: </p>
                            </div>
                            <div className="value" onClick={() => dispatch(showPopupDropdownStoreName())}>{condFiltersStoreName.OptionSelectStore.isSelect ? condFiltersStoreName.OptionSelectStore.name : `Đã chọn ${condFiltersStoreName.OptionSelectStore.totalStoreSelect} cửa hàng` }</div>
                            <IcArrowDownGray className="arrow-right" onClick={() => dispatch(showPopupDropdownStoreName())} />
                        </div>
                        {isShowDropdownListStore && dropdownFilterStore}
                    </FilterControlStore>
                </WrapFilterTransactionControl>
                <Button
                    iconBtn={<IcRefresh />}
                    text="Reset"
                    className="btn-reset"
                    onClick={handleRefresh}
                /> 
            </WrapFilterTransactionSearch>
            <WrapFilterTransactionCheckbox>
                <div className="filter-checkbok">
                {
                        condFiltersTransactionBank.OptionSelectCheckbox.map((item, index) => (
                            <CheckBoxControl 
                                key={index}
                                title={item.name}
                                iconCheckBox={iconCheckBox}
                                className="info-check"
                                isChecked={item.isDisabled !== true && item.isSelect}
                                handleCheck={() => handleSelectCheckbox(item.id)}
                                isDisabled={item.isDisabled === true ? true : false}
                            />
                        ))
                    }
                </div>
                <div className="filter-checkbok">
                    <div className="filter-checkbok-text">Ngân hàng: </div>
                    <div className="filter-checkbok-bank">
                        <InputDropdownBank
                            reqType={BANK}
                            iconCheckBox={iconCheckBox}
                            listDropdown={condFiltersTransactionBank.supplier}
                            listChecked={condFiltersTransactionBank.supplier.filter((item) => item.isSelect).map((item) => item.key)}
                            handleCheckItem={handleSelectCheckboxBank}
                        />
                    </div>
                </div>
            </WrapFilterTransactionCheckbox>
        </WrapFilterTransaction>
    )
}

export default FilterTransactionBank
