import { AMOUNT, BANK, BANK_TX_ID, DEVICE_ID, DEVICE_NAME, FACEPAY_TX_ID, FIRMWARE_CAMERA, FIRMWARE_DEVICE, FREE_MOMORY, LAST_ONLINE_TIME, LOCATION, MERCHANT, MERCHANT_TX_ID, PROFILE_ID, REFUND, SERIAL_NUMBER, STATUS_ID, TIME_TRANSACTION, TOTAL_MEMORY, VERSION_CODE, VERSION_CODE_LAUNCHER, VERSION_NAME, VERSION_NAME_LAUNCHER, VOUCHER } from "../constant/keys";

// BANK
export const dataSelectCheckBoxExportBank = [
    {  
        id: 1,
        key: FACEPAY_TX_ID,
        name: 'Facepay Tx ID',
        title: 'facepayTrxCode',
        isSelect: true
    },
    {  
        id: 2,
        key: BANK,
        name: 'Bank',
        title: 'supplier',
        isSelect: true
    },
    {  
        id: 3,
        key: BANK_TX_ID,
        name: 'Bank Tx ID',
        title: 'bankTrxCode',
        isSelect: true
    },
    {  
        id: 4,
        key: AMOUNT,
        name: 'Số tiền',
        title: 'transAmount',
        isSelect: true
    },
    {  
        id: 5,
        key: MERCHANT_TX_ID,
        name: 'Merchant Tx ID',
        title: 'merchantTrxCode',
        isSelect: true
    },
    {  
        id: 6,
        key: TIME_TRANSACTION,
        name: 'Thời gian giao dịch',
        title: 'createdAt',
        isSelect: true
    },
    {  
        id: 7,
        key: PROFILE_ID,
        name: 'Profile ID',
        title: 'profileId',
        isSelect: true
    },
    {  
        id: 8,
        key: VOUCHER,
        name: 'Mã khuyến mãi',
        title: 'codePromotion',
        isSelect: true
    },
    {  
        id: 9,
        key: MERCHANT,
        name: 'Merchant',
        title: 'merchantCode',
        isSelect: true
    },
    {  
        id: 10,
        key: REFUND,
        name: 'Hoàn tiền',
        title: 'refund',
        isSelect: true
    },
]

// FACEPAY
export const dataSelectCheckBoxExportFacepay = [
    {  
        id: 1,
        key: FACEPAY_TX_ID,
        name: 'Facepay Tx ID',
        title: 'facepayTrxCode',
        isSelect: true
    },
    {  
        id: 2,
        key: AMOUNT,
        name: 'Số tiền',
        title: 'transAmount',
        isSelect: true
    },
    {  
        id: 3,
        key: BANK_TX_ID,
        name: 'Bank Tx ID',
        title: 'bankTrxCode',
        isSelect: true
    },
    {  
        id: 4,
        key: TIME_TRANSACTION,
        name: 'Thời gian giao dịch',
        title: 'createdAt',
        isSelect: true
    },
    {  
        id: 5,
        key: MERCHANT_TX_ID,
        name: 'Merchant Tx ID',
        title: 'merchantTrxCode',
        isSelect: true
    },
    {  
        id: 6,
        key: VOUCHER,
        name: 'Mã khuyến mãi',
        title: 'codePromotion',
        isSelect: true
    },
    {  
        id: 7,
        key: PROFILE_ID,
        name: 'Profile ID',
        title: 'profileId',
        isSelect: true
    },
    {  
        id: 8,
        key: REFUND,
        name: 'Hoàn tiền',
        title: 'refund',
        isSelect: true
    },
    {  
        id: 9,
        key: MERCHANT,
        name: 'Merchant',
        title: 'merchantCode',
        isSelect: true
    },
]

export const dataSelectCheckbox = [
    {  
        id: 1,
        name: 'Thành công',
        key: 'success',
        isSelect: true,
        isDisabled: false
    },
    {  
        id: 2,
        name: 'Thất bại',
        key: 'failed',
        isSelect: true,
        isDisabled: false
    },
    {  
        id: 3,
        name: 'Không hoàn tiền',
        key: 'noRefund',
        isSelect: false,
        isDisabled: true
    },
    {  
        id: 4,
        name: 'Yêu cầu hoàn tiền',
        key: 'isRefunded',
        isSelect: false,
        isDisabled: true
    },
    {  
        id: 5,
        name: 'Đã hoàn tiền',
        key: 'refund',
        isSelect: false,
        isDisabled: true
    },
    {  
        id: 6,
        name: 'Khuyến mãi',
        key: 'promotion',
        isSelect: true,
        isDisabled: false
    },
    {  
        id: 7,
        name: 'K.Khuyến mãi',
        key: 'noPromotion',
        isSelect: true,
        isDisabled: false
    },
]


// FAPCE WALLET
export const dataSelectCheckBoxTypeTransaction = [
    {
        id: 1,
        title : 'Nạp tiền',
        key: "deposit",
        isSelect: true,
    },
    {
        id: 2,
        title : 'Liên kết thẻ',
        key: "linkCard",
        isSelect: true,
    },
    // {
    //     id: 3,
    //     title : 'C. tiền tới ví',
    //     key: "toAnotherWallet",
    //     isSelect: true,
    // },
    // {
    //     id: 4,
    //     title : 'C. tiền tới ngân hàng',
    //     key: "toBank",
    //     isSelect: true,
    // },
    // {
    //     id: 5,
    //     title : 'Rút tiền',
    //     key: "withDrawal",
    //     isSelect: true,
    // },
]

export const dataSelectCheckboxWallet = [
    {  
        id: 1,
        name: 'Thành công',
        key: 'success',
        isSelect: true,
    },
    {  
        id: 2,
        name: 'Thất bại',
        key: 'failed',
        isSelect: true,
    },
]

export const dataSelectCheckBoxExportWallet = [
    {  
        id: 1,
        name: 'Facepay Tx ID',
        title: 'facepayTrxCode',
        isSelect: true
    },
    {  
        id: 2,
        name: 'Số tiền',
        title: 'amount',
        isSelect: true
    },
    {  
        id: 3,
        name: 'Profile ID',
        title: 'profileId',
        isSelect: true
    },
    {  
        id: 4,
        name: 'Thời gian giao dịch',
        title: 'createAt',
        isSelect: true
    },
    {  
        id: 5,
        name: 'Username',
        title: 'userName',
        isSelect: true
    },
    {  
        id: 6,
        name: 'Tài khoản nhận',
        title: 'to',
        isSelect: true
    },
    {  
        id: 7,
        name: 'Tài khoản gửi',
        title: 'from',
        isSelect: true
    },
    {  
        id: 8,
        name: 'Trạng thái',
        title: 'status',
        isSelect: true
    }
];

// DEVICE MANAGER
export const dataSelectCheckBoxExportDevice = [
    {  
        id: 1,
        name: 'Trạng thái',
        key: STATUS_ID,
        isSelect: true
    },
    {  
        id: 2,
        name: 'VersionCode Launcher',
        key: VERSION_CODE_LAUNCHER,
        isSelect: true
    },
    {  
        id: 3,
        name: 'Mã thiết bị',
        key: DEVICE_ID,
        isSelect: true
    },
    {  
        id: 4,
        name: 'SerialNumber',
        key: SERIAL_NUMBER,
        isSelect: true
    },
    {  
        id: 5,
        name: 'Tên thiết bị',
        key: DEVICE_NAME,
        isSelect: true
    },
    {  
        id: 6,
        name: 'Tổng bộ nhớ',
        key: TOTAL_MEMORY,
        isSelect: true
    },
    {  
        id: 7,
        name: 'Merchant',
        key: MERCHANT,
        isSelect: true
    },
    {  
        id: 8,
        name: 'Bộ nhớ trống',
        key: FREE_MOMORY,
        isSelect: true
    },
    {  
        id: 9,
        name: 'Location',
        key: LOCATION,
        isSelect: true
    },
    {  
        id: 10,
        name: 'Firmware thiết bị',
        key: FIRMWARE_DEVICE,
        isSelect: true
    },
    {  
        id: 11,
        name: 'Phiên bản app',
        key: VERSION_NAME,
        isSelect: true
    },
    {  
        id: 12,
        name: 'Firmware camera',
        key: FIRMWARE_CAMERA,
        isSelect: true
    },
    {  
        id: 13,
        name: 'VersionCode',
        key: VERSION_CODE,
        isSelect: true
    },
    {  
        id: 14,
        name: 'Lần cuối kết nối',
        key: LAST_ONLINE_TIME,
        isSelect: true
    },
    {  
        id: 15,
        name: 'Phiên bản Launcher',
        key: VERSION_NAME_LAUNCHER,
        isSelect: true
    },
]

export const dataSelectCheckCustomerFacepay = [
    {
        id: 1,
        text: 'Tất cả',
        key: '*'
    },
    {
        id: 2,
        text: 'Đang kích hoạt',
        key: 'Active'
    },
    {
        id: 3,
        text: 'Đang khoá',
        key: 'Locked'
    },
    {
        id: 4,
        text: 'Chưa eKYC',
        key: 'NotEkyc'
    },
    {
        id: 5,
        text: 'Payme yêu cầu cập nhật',
        key: 'PmRequestUpdateEkyc'
    },
]