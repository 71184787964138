import Axios from 'axios'

//host
import { hostCustomer } from '../../../constant/host';

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';


//types
import * as types from './types'

const apiGetListReason = `${hostCustomer}/access/reason`

export const getListReason = () => async dispatch => {
    try {
        const res = await Axios.post(apiGetListReason)
        const { data } = res;
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_LIST_REASON,
                payload: {
                    reasonRejectUser: data.data?.reasonRejectUser || [],
                    reasonBlockCustomer: data.data?.reasonBlockCustomer || []
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_REASON_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_LIST_REASON_ERR))
    }
}

export const clearListReason = () => async dispatch => {
    dispatch({type: types.CLEAR_LIST_REASON})
}