import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//style
import { WrapFaceRecognizeInfo } from "./index.styles";

//image
import IcTransPhotoDefault from "../../../../../../../../../assets/images/IcTransPhotoDefault";
import IcRefresh from "../../../../../../../../../assets/images/IcRefresh";
import IcEdit from "../../../../../../../../../assets/images/IcEdit";
import IcTimeUpdateGray from "../../../../../../../../../assets/images/IcTimeUpdateGray";

//component
import PopupShowMedia, {
  IMAGE,
} from "../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia";
import Portal from "../../../../../../../../Control/Portal";
import PopupConfirm from "./PopupConfirm";
import PopupEditInfo from "./PopupEditInfo";
import { PASSPORT } from "../../../../../../../../../data/dataCustomer";
import { convertDateTime } from "../../../../../../../../../helper/convertDateTime";
import PopupShowInfoeKYC from "./PopupShowInfoeKYC";

const CONFIRM = "CONFIRM";
const EDIT = "EDIT";

const FaceZoomInfoKYC = ({ item, index, handleShowInfoKyc }) => {
  
  const [showMedia, setShowMedia] = useState(false);
  const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
  const [keyShowForm, setKeyShowForm] = useState("");
  const [listeKYC, setListeKYC] = useState([]);
  const [src, setSrc] = useState("");

  const { eKycStatus } = useSelector((state) => ({
    eKycStatus: state.customerReducer.customerDetailReducer.eKycStatus,
  }));

  useEffect(() => {
    setListeKYC([item.images?.frontUrl, item.images?.backUrl]);
  }, []);

  useEffect(() => {
    if (!listeKYC) return;
    if (listeKYC?.length > 0) {
      setSrc(listeKYC[0]);
    }
  }, [listeKYC]);

  const _handleZoomPhotoFrontUrl = () => {
    if (isErrCustomerPhoto) return;
    setSrc(item.images?.frontUrl)
  };

  const handleConfirm = () => {
    setKeyShowForm(CONFIRM);
  };

  const handleEdit = () => {
    setKeyShowForm(EDIT);
  };

  useEffect(() => {
    setIsErrCustomerPhoto(false);
  }, [item.img]);

  const handleZoomPhotoeKYC = (item) => {
    setSrc(item)
    setShowMedia(true)
  }

  return (
    <WrapFaceRecognizeInfo>
      <div className="face-infos">
        <div className="title-face">
          <div className="title-value">Hình ảnh giấy tờ </div>
          {index === 0 ? (
            eKycStatus?.isRequestUpdateEKYC ? (
              <div className="title-time-update">
                <IcTimeUpdateGray />
                Đã yêu cầu cập nhật giấy tờ vào lúc{" "}
                {convertDateTime(eKycStatus?.requestAt, "HH:mm - DD/MM/YYYY")}
              </div>
            ) : (
              <div className="title-right">
                <div className="title-btn" onClick={handleConfirm}>
                  <IcRefresh />
                  <span className="value">Yêu cầu cập nhật</span>
                </div>
                <div className="title-btn" onClick={handleEdit}>
                  <IcEdit />
                  <span className="value">Tuỳ chỉnh giấy tờ</span>
                </div>
              </div>
            )
          ) : (
            ""
          )}
          {keyShowForm === CONFIRM && (
            <Portal>
              <PopupConfirm
                item={item}
                onClickClosePopup={() => setKeyShowForm("")}
              />
            </Portal>
          )}
          {keyShowForm === EDIT && (
            <Portal>
              <PopupEditInfo
                item={item}
                onClickClosePopup={() => setKeyShowForm("")}
              />
            </Portal>
          )}
        </div>
        <div className="face-kyc">
          {item.ekycType === PASSPORT ? (
            <div
              className="wrap-media face-img"
              onClick={_handleZoomPhotoFrontUrl}
            >
              {isErrCustomerPhoto ? (
                <div className="wrapper">
                  <IcTransPhotoDefault />
                </div>
              ) : (
                <img
                  src={item.images.frontUrl || ""}
                  alt="face kyc"
                  onError={() => setIsErrCustomerPhoto(true)}
                />
              )}
            </div>
          ) : (
            <>
              {listeKYC?.length !== 0 ? (
                <>
                  <div className="wrap-media face-img">
                    {listeKYC?.map((item, index) => (
                      <img
                        src={item}
                        key={index}
                        className="face kyc"
                        onClick={() => {
                          handleZoomPhotoeKYC(item);
                        }}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="wrapper">
                  <IcTransPhotoDefault />
                </div>
              )}
              {/* <div className="wrap-media face-img" onClick={_handleZoomPhotoFrontUrl}>
                  {isErrCustomerPhoto ? (
                    <div className="wrapper">
                      <IcTransPhotoDefault />
                    </div>
                  ) : (
                    <img
                      src={item.images.frontUrl || ""}
                      alt="face kyc"
                      onError={() => setIsErrCustomerPhoto(true)}
                    />
                  )}
                </div>
                <div className="wrap-media face-img" onClick={_handleZoomPhotoBackUrl}>
                  {isErrCustomerPhoto ? (
                    <div className="wrapper">
                      <IcTransPhotoDefault />
                    </div>
                  ) : (
                    <img
                      src={item.images.backUrl || ""}
                      alt="face kyc"
                      onError={() => setIsErrCustomerPhoto(true)}
                    />
                  )}
                </div> */}
            </>
          )}
          {showMedia && (
            <Portal>
              <PopupShowInfoeKYC
                listeKYC={listeKYC}
                handleShowInfoKyc={handleShowInfoKyc}
                item={item}
                closePopup={() => setShowMedia(false)}
                src={src}
                setSrc={setSrc}
              />
            </Portal>
          )}
        </div>
      </div>
    </WrapFaceRecognizeInfo>
  );
};

export default FaceZoomInfoKYC;
