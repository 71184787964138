import * as types from './type'

const intitialState = {
    listStoreName: [],
    listBank: [],
    condFiltersStoreName: {
        name: '',
        OptionSelectStore: {
            id: 1,
            name: "Tất cả cửa hàng",
            isSelect: true,
            totalStoreSelect: 0
        }
    },
    condCheckboxAll: {
        id: 0,
        title: "Tất cả",
        isSelect: true
    },
    isShowDropdownListStore: false,
    isCheckSearch: false,

    total: 0,
    reqType: "FACEPAY_TERMINAL"
}


const transactionOtherReducer = (state = intitialState, action) => {

    switch (action.type) {

        case types.GET_LIST_STORE_NAME:
        case types.UPDATE_LIST_STORE_NAME_TEMP:
            return {
                ...state,
                listStoreName: action.payload.listStoreName,
                total: action.payload.total,
            }
        
        case types.UPDATE_LIST_STORE_NAME_CONDFILTERS: 
            return {
                ...state,
                condFiltersStoreName: {
                    ...state.condFiltersStoreName,
                    ...action.condFiltersStoreName,
                },
            }
        
        case types.RESET_STORE_NAME_CONDFILTERS: 
            return {
                ...state,
                condFiltersStoreName: intitialState.condFiltersStoreName
            }

        case types.RESET_LIST_STORE_NAME: 
            return {
                ...state,
                listStoreName: []
            }
        
        // get list bank
        case types.GET_LIST_BANK: 
            return {
                ...state,
                listBank: action.payload.listBank
            }

        // popup
        case types.SHOW_POPUP_DROPDOWN_STORE_NAME: 
            return {
                ...state,
                isShowDropdownListStore: !state.isShowDropdownListStore
            }
        case types.HIDE_POPUP_DROPDOWN_STORE_NAME: 
            return {
                ...state,
                isShowDropdownListStore: false
            }

        // change tab
        case types.CHANGE_TAB_TRANSACTION:
            return {
                ...state,
                reqType: action.reqType
            }


        // check search store
        case types.IS_CHECK_SEARCH_STORE_NAME: 
            return {
                ...state,
                isCheckSearch: true
            }

        case types.RESET_IS_CHECK_SEARCH_STORE_NAME: 
            return {
                ...state,
                isCheckSearch: false
            }

        case types.UPDATE_CONDFILTERS_CHECKBOX_ALL: 
            return {
                ...state,
                condCheckboxAll: {
                    ...state.condCheckboxAll,
                    ...action.condCheckboxAll,
                },
            }

        default:
            return state
    }
}

export default transactionOtherReducer