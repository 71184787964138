import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import * as styles from "./index.styles";

// images
import IcFacePrintDefault from "../../../../../../../assets/images/IcFacePrintDefault";
import IcLockWhite from "../../../../../../../assets/images/IcLockWhite";
import IcPhoneBlueDefault from "../../../../../../../assets/images/IcPhoneBlueDefault";
import IcTimeBlue from "../../../../../../../assets/images/IcTimeBlue";
import IcEmailBgBlue from "../../../../../../../assets/images/IcEmailBgBlue";
import IcActive from "../../../../../../../assets/images/IcActive";
import IcLocked from "../../../../../../../assets/images/IcLockBgRed";
import IcCensorshipDefault from "../../../../../../../assets/images/IcCensorshipDefault";
import IcCheckBgBlue from "../../../../../../../assets/images/IcCheckBgBlue";
import IcUserBlueBgWhite from "../../../../../../../assets/images/IcUserBlueBgWhite";
import IcUnlockWhite from "../../../../../../../assets/images/IcUnlockWhite";


//component
import Button from "../../../../../../Control/Button";
import { convertDateTime } from "../../../../../../../helper/convertDateTime";
import Portal from "../../../../../../Control/Portal";
import PopupLocked from "./PopupLocked";
import PopupOpenLocked from "./PopupOpenLocked";
import EmptyDataControl from "../../../../../../Control/EmptyDataControl";
import LoadingLocal from "../../../../../../Control/LoadingLocal";

// redux
import { dataBankType } from "../../../../../../../data/dataBankType";
import { popupLockDetailCustomer, popupOpenLockDetailCustomer } from "../../../../../../../redux/customer/customerDetail/action";
import { LOADING_DETAIL_CUSTOMER } from "../../../../../../../redux/customer/customerDetail/types";

// constant
import { apiGetBankCustomerAvatar } from "../../../../../../../constant/host";
import { FACEPAY } from "../../../../../../../constant/keys";

const ACTIVATED = "ACTIVATED"

export default function Info() {

  const { details, reasonBlockCustomer, isTypeLoading, isPopupOpenLockCustomer, isPopupLockCustomer, isShowPopupNote } = useSelector((state) => ({
    details: state.customerReducer.customerDetailReducer.details,

    isPopupOpenLockCustomer: state.customerReducer.customerDetailReducer.isPopupOpenLockCustomer,
    isPopupLockCustomer: state.customerReducer.customerDetailReducer.isPopupLockCustomer,

    reasonBlockCustomer: state.censorshipReducer.listReasonReducer.reasonBlockCustomer,
    isShowPopupNote: state.customerReducer.customerNoteReducer.isShowPopupNote,

    isTypeLoading: state.loading[LOADING_DETAIL_CUSTOMER]
  }));

  const dispatch = useDispatch()

  const [isErrImg, setIsErrImg] = useState(false);

  useEffect(() => {
    setIsErrImg(false)
  }, [details?.id])

  const handleShowPopupLockCustomer = () => {
    if(isShowPopupNote) return
    dispatch(popupLockDetailCustomer())
  }

  const handleShowPopupOpenLockCustomer = () => {
    if(isShowPopupNote) return
    dispatch(popupOpenLockDetailCustomer())
  }
  
  return (
    <styles.Bound className="block">
      {
        isTypeLoading ? <LoadingLocal /> : (
          !details ? (
            <div className="wrap-empty">
              <EmptyDataControl
                icon={<IcCensorshipDefault />}
                text={"Hiện tại chưa có thông tin khách hàng"}
              />
            </div>
          ) : (
              <div className="wrap-detail">
                <div className="info">
                  <div className="info-left">
                    <div className="info-face">
                      {isErrImg ? (
                        <IcFacePrintDefault className="face-info" />
                      ) : (
                          <img
                            className="face-info"
                            src={details?.accountType === FACEPAY ? details?.thumbnailUrl : apiGetBankCustomerAvatar(details?.thumbnailId)}
                            alt="faceInfo"
                            onError={() => setIsErrImg(true)}
                          />
                        )}
                      <div className="bank-logo">
                        {details?.accountType &&
                          dataBankType[details?.accountType].icon}
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="info-name">{details?.fullName || "-"}</div>
                      <div className="info-type">
                        {!details?.isLock ? (
                          <div className="info-status">
                            <IcActive className="icon" />
                            <div className="text">Đang kích hoạt</div>
                          </div>
                        ) : (
                            <div className="info-locked">
                              <IcLocked className="icon" />
                              <div className="text">Đã khoá</div>
                            </div>
                          )}
                        <div className="info-id">{details?.profileId || "-"}</div>
                        {
                          details?.accountType === FACEPAY ? (
                            <div className="info-user">
                              <div className="icon">
                                <IcUserBlueBgWhite />
                              </div>
                              <div className="info-user-text">
                                {details.userName || "-"}
                              </div>
                            </div>
                          ) : ""
                        }
                      </div>
                      {details?.isLock && (
                        <div className="locked-error">
                          Bắt đầu khóa từ{" "}
                          <span className="error">
                            {convertDateTime(
                              details?.lockTime,
                              "HH:mm - DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                      )}

                    </div>
                  </div>
                  {
                    !details?.isLock ? (
                    <Button
                      text="Khoá tài khoản"
                      iconBtn={<IcLockWhite />}
                      className="btn-locked"
                      onClick={handleShowPopupLockCustomer}
                    />
                  ) : (
                    <Button
                      text="Mở khoá"
                      iconBtn={<IcUnlockWhite />}
                      className="btn-open"
                      onClick={handleShowPopupOpenLockCustomer}
                    />
                  )}
                  {isPopupLockCustomer && (
                    <Portal>
                      <PopupLocked
                        profileId={details?.id}
                        reason={reasonBlockCustomer}
                      />
                    </Portal>
                  )}
                  {isPopupOpenLockCustomer && (
                    <Portal>
                      <PopupOpenLocked
                        fullName={details?.fullName}
                        profileId={details?.id}
                      />
                    </Portal>
                  )}
                </div>
                <div className="more_info">
                  <div className="more_info-item">
                    <div className="icon">
                      <IcPhoneBlueDefault />
                    </div>
                    <div className="more_info-text">
                      <div className="value">{details?.phone || "-"}</div>
                      <div className="text">Số điện thoại</div>
                    </div>
                  </div>
                  <div className="more_info-item">
                    <div className="icon">
                    <IcTimeBlue />            
                    </div>
                    <div className="more_info-text">
                      <div className="value">
                        {details?.accountType === FACEPAY ? convertDateTime(details?.registerAt, "HH:mm - DD/MM/YYYY") : 
                          convertDateTime(details?.createdAt, "HH:mm - DD/MM/YYYY")}
                      </div>
                      <div className="text">Thời gian đăng ký</div>
                    </div>
                  </div>
                  <div className="more_info-item">
                    <div className="icon">
                      <IcEmailBgBlue />
                    </div>
                    <div className="more_info-text">
                      <div className="value" title={details?.email?.email}>
                        <div className="value-email">
                        {details?.email?.email || "-"}
                        </div>
                        {details?.email?.status === ACTIVATED ? <IcCheckBgBlue /> : ""}
                      </div>
                      <div className="text">Email đăng ký</div>
                    </div>
                  </div>
                </div>
              </div>
            )
        )
      }
    </styles.Bound>
  );
}
