import styled from "styled-components";

const WrapPopupShowInfoeKYC = styled.div`
        height: 602px;
        min-width: 670px;
        position: relative;
        /* background: gray; */
        display: flex;
        .ic-close {
            position: absolute;
            bottom: calc(100% + 12px);
            right: 5px;
            cursor: pointer;
        }

        .wrap-info-eKyc{
            background: #fff;
            height: calc(100% - 52px);
            display: flex;
            flex-direction: column;
            padding: 26px 10px 26px 14px;
        }
        .wrap-info-face{ 
            position: relative;
            max-width: 950px;
            height: 100%;
            width: 100%;
            .wrap-face-default{
                width: 320px;
                height: 320px;
                border-radius: unset;
            }
            .wrap-face-zoom{
                width: 950px;
                height: 100%;
                object-fit: contain;
                border-radius: 3px;
                background: #000;
            }
            .face-img-item{
                .img-item-check{
                    position: absolute;
                    bottom: 16px;
                    left: 16px;
                    .list-item{
                        height: 44px;
                        .wrap-face-item{
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            object-fit: contain;
                            opacity: 0.4;
                            border: 2px solid transparent;
                            &.active{
                                opacity: 1;
                                border: 2px solid #A31E23;
                                border-radius: 4px;
                            }
                        }

                    }
                }
            }
        }
        
`

export {
    WrapPopupShowInfoeKYC
}