import React from 'react'

const IcArrowLeftRight = (props) => {
  return (
    <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.87476 5.20971H13.7942" stroke="#33ADFF" stroke-width="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.105 2.52026L13.7945 5.20983L11.105 7.8994" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.79418 10.8347H1.87476" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.56445 8.14526L1.87489 10.8348L4.56445 13.5244" stroke="#33ADFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IcArrowLeftRight