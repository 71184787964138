import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

//style
import { OverlayFullScreen } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapCustomerTransHistoryDetailsItem } from './index.style'

//image
import IcCloseWhite from '../../../../../../../../../../assets/images/IcCloseWhite'
import IcCheckBgBlue from '../../../../../../../../../../assets/images/IcCheckBgBlue'
import IcCloseRedMini from '../../../../../../../../../../assets/images/IcCloseRedMini'
import IcRefundWhite from '../../../../../../../../../../assets/images/IcRefundWhite'
import IcPlayCircle from '../../../../../../../../../../assets/images/IcPlayCircle'
import IcTransVerifyVideo from '../../../../../../../../../../assets/images/IcTransVerifyVideo'
import IcZoomIn from '../../../../../../../../../../assets/images/IcZoomIn'
import IcTransPhotoDefault from '../../../../../../../../../../assets/images/IcTransPhotoDefault'
import Logo_DefautBank from "../../../../../../../../../../assets/images/Default_Bank.png";

//component
import Button from '../../../../../../../../../Control/Button'
import Portal from '../../../../../../../../../Control/Portal'
import PopupShowMedia, { IMAGE, VIDEO } from '../../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia'
import PopupAttachNote from '../../../PopupAttachNote'
import { useOnClickOutside } from '../../../../../../../../../../hook/useClickOutside'
import PopupConfirmApprove from '../TransactionDetail/PopupComfirmApprove'
import EmptyDataControl from '../../../../../../../../../Control/EmptyDataControl'

//help
import { convertDateTime } from '../../../../../../../../../../helper/convertDateTime'
import numberFormatToThousand from '../../../../../../../../../../helper/numberFormatToThousand'
import checkTimeTransactionComplete from '../../../../../../../../../../helper/checkTimeTransactionComplete'

//images
import IcNoteBlue from '../../../../../../../../../../assets/images/IcNoteBlue'
import IcTimeMini from '../../../../../../../../../../assets/images/IcTimeMini'
import IcBankMini from '../../../../../../../../../../assets/images/IcBankMini'
import IcStoreMini from '../../../../../../../../../../assets/images/IcStoreMini'
import IcEmailBgBlue from '../../../../../../../../../../assets/images/IcEmailBgBlue'
import IcPhoneBlueDefault from '../../../../../../../../../../assets/images/IcPhoneBlueDefault'
import IcUserBlueDefault from '../../../../../../../../../../assets/images/IcUserBlueDefault'
import IcFacePrintDefault from '../../../../../../../../../../assets/images/IcFacePrintDefault'
import IcMerchantLogoDefault from '../../../../../../../../../../assets/images/IcMerchantLogoDefault'
import IcGS25 from '../../../../../../../../../../assets/images/IcGS25'
import IcNotifyError from '../../../../../../../../../../assets/images/IcNotifyError'
import IcSearchDefault from '../../../../../../../../../../assets/images/IcSearchDefault'
import IcArrowLeftRight from '../../../../../../../../../../assets/images/IcArrowLeftRight'
import IcTimeOutlineGray from '../../../../../../../../../../assets/images/IcTimeOutlineGray'
import IcPromotionTrans from '../../../../../../../../../../assets/images/IcPromotionTrans'
import IcAmountTrans from '../../../../../../../../../../assets/images/IcAmountTrans'
import IcAmountDiscountTrans from '../../../../../../../../../../assets/images/IcAmountDiscountTrans'
import IcRefundGray from '../../../../../../../../../../assets/images/IcRefundGray'
import IcRefundDoneBgWhite from '../../../../../../../../../../assets/images/IcRefundDoneBgWhite'

import { dataBankType, dataLogoMerchant } from '../../../../../../../../../../data/dataBankType'
import { apiGetBankCustomerAvatar, apiGetBankCustomerTransactionVideo } from '../../../../../../../../../../constant/host'

import { ACCOUNTANT, CTG, FACEPAY, POPUP_NOTE_TRANSACTION_CUSTOMER } from '../../../../../../../../../../constant/keys'
import { mapErrorTransaction } from '../../../../../../../../../../helper/mapErrorTransaction'




const CustomerTransDetail = ({ data, onClickClosePopup, detailsTrans }) => {

    const {
        bioId,
        facepayTransCode,
        status,
        storeName,
        createdAt,
        transAmount,
        bankTransCode,
        merchantCode,
        merchantTransCode,
        supplier,
        swiftCode,
        thumbnailId,
        videoId,
        completeTime,
        paymentcode,
        paymentdescription,
        isWallet, 
        profileId,
        refund,
        refundBy,
        refundAt,
        codePromotion,
        discountAmount,
        originAmount
    } = data;

    const refPopup = useRef()
    const [showMedia, setShowMedia] = useState(null);

    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrCustomerPhoto, setIsErrCustomerPhoto] = useState(false);
    const [isErrCustomerVideo, setIsErrCustomerVideo] = useState(false);
    const [isShowPopupNote, setIsShowPopupNote] = useState(false)
    const [isShowPopupComfirmRefund, setIsShowPopupComfirmRefund] = useState(false)

    const accountant = localStorage.getItem('FACEPAY_PORTAL_LOGIN')
    let accountantShowRefund = JSON.parse(accountant)
    
    const { details, reqType } = useSelector((state) => ({
        details: state.customerReducer.customerDetailReducer.details,
        reqType: state.customerReducer.listCustomerReducer.reqType
    }))

    useEffect(() => {
        setIsErrImg(false)
    }, [details.thumbnailId])

    useEffect(() => {
        setIsErrCustomerPhoto(false)
    }, [thumbnailId])

    useEffect(() => {
        setIsErrCustomerVideo(false)
    }, [videoId])

    const _handleZoomPhoto = () => {
        if (isErrCustomerPhoto) return;
        setShowMedia({
            type: IMAGE,
            url: thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : "",

        });
    };

    const _handleZoomVideo = () => {
        if (isErrCustomerVideo) return;
        setShowMedia({
            type: VIDEO,
            url: videoId ? apiGetBankCustomerTransactionVideo(videoId) : "",
        });
    };

    useOnClickOutside(refPopup, ()=> {
        if(isShowPopupComfirmRefund || showMedia) return
        onClickClosePopup()
    })

    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          onClickClosePopup();
        }
    });

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCustomerTransHistoryDetailsItem ref={refPopup}>
                    <IcCloseWhite className="icon-close" onClick={onClickClosePopup} />
                    <div className={status === 1 ? "wrap-status" : "wrap-status fail"}>
                        <div className="content">
                            {status === 1 ? <IcCheckBgBlue className="icon" /> : <IcCloseRedMini className="icon" />}
                            <p className="facepay-id">{facepayTransCode ? `#${facepayTransCode}` : "-"}</p>
                        </div>
                        <div className="content">
                            <div className="time">{createdAt ? convertDateTime(createdAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</div>
                            {
                                refund === 1 ? (
                                    <div className='refund-done'>
                                        <IcRefundDoneBgWhite />
                                        <p>Đã hoàn tiền</p>
                                    </div>
                                ):
                                accountantShowRefund.privileges
                                .includes(ACCOUNTANT) ? 
                                (
                                    <Button
                                    text="Hoàn tiền"
                                    iconBtn={supplier === CTG ? <IcRefundWhite /> : <IcRefundGray />}
                                    className={supplier === CTG ? "btn-refund" : "disable"}
                                    onClick={() => setIsShowPopupComfirmRefund(true)}
                                    isDisable={supplier === CTG ? false : true}
                                    />
                                ) : ""
                                
                            }
                        </div>
                    </div>
                    {
                        status === 0 &&
                        <div className="wrap-reason">
                            <IcNotifyError className="error-icon" />
                            <div className="errro-text">
                                {mapErrorTransaction({
                                    supplier,
                                    paymentCode: paymentcode,
                                    paymentDescription: paymentdescription,
                                    isWallet
                                })}
                            </div>
                        </div>
                    }
                    {
                        refund === 1 ? (
                            <div className='wrap-refund'>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcArrowLeftRight />
                                    </div>
                                    <p>{`${refundBy || "-"} đã thực hiện hoàn tiền`}</p>
                                </div>
                                <div className='refund'>
                                    <div className='icon'>
                                        <IcTimeOutlineGray />
                                    </div>
                                    <p className='refund-time'>{refundAt ? convertDateTime(refundAt, "HH:mm:ss - DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                        ) : ""
                    }
                    <div className="wrap-info-transaction">
                        <div className="info-transaction">
                            <div className="block-info">
                                <p className="title">Thông tin giao dịch</p>
                                <div className="wrap-info">
                                <div className="info">
                                        <IcAmountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{numberFormatToThousand(transAmount) || 0}</p>
                                            <p className="text">Số tiền (đ)</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcPromotionTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{codePromotion || "-"}</p>
                                            <p className="text">Mã khuyến mãi</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountDiscountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{numberFormatToThousand(discountAmount) || 0}</p>
                                            <p className="text">Số tiền khấu trừ</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcAmountDiscountTrans className="icon" />
                                        <div className="content">
                                            <p className="value">{      numberFormatToThousand(originAmount) || 0}</p>
                                            <p className="text">Số tiền gốc (đ) </p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <IcTimeMini className="icon" />
                                        <div className="content">
                                            <p className="value">{checkTimeTransactionComplete(completeTime)}</p>
                                            <p className="text">TG hoàn tất giao dịch</p>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div className="block-info">
                                <p className="title">Phương thức thanh toán</p>
                                <div className="wrap-info">
                                    <div className="info">
                                        <IcBankMini className="icon" />
                                        <div className="content">
                                            <p className="value">{bankTransCode || "-"}</p>
                                            <p className="text">Bank Tx ID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="icon">{reqType !== FACEPAY ? dataBankType[supplier]?.icon : dataBankType[swiftCode]?.icon || <img src={Logo_DefautBank} className="icon" /> }</div>
                                        <div className="content">
                                            <p className="value">{reqType !== FACEPAY ? dataBankType[supplier]?.text : dataBankType[swiftCode]?.text || "-"}</p>
                                            <p className="text">Tài khoản thanh toán</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <p className="title">Cửa hàng</p>
                                <div className="wrap-info">
                                    <div className="info">
                                        <IcStoreMini className="icon" />
                                        <div className="content">
                                            <p className="value">{merchantTransCode || "-"}</p>
                                            <p className="text">Merchant Tx ID</p>
                                        </div>
                                    </div>
                                    <div className="info">
                                        {
                                            merchantCode ?
                                                <div className="icon">{dataLogoMerchant[merchantCode]?.icon}</div>
                                                :
                                                <IcMerchantLogoDefault />

                                        }
                                        <div className="content">
                                            <p className="value">{storeName || "-"}</p>
                                            <p className="text">Cửa hàng</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-info-customer">
                                <p className="title">Khách hàng</p>
                                {
                                    details?.profileId ? (
                                        <div className="wrap-info-customer">
                                            <div className="info">
                                                {
                                                    isErrImg ?
                                                        <IcFacePrintDefault className="icon" />
                                                        :
                                                        <img
                                                            className="icon"
                                                            src={reqType === FACEPAY ? details?.thumbnailUrl : apiGetBankCustomerAvatar(details?.thumbnailId)}
                                                            alt="facePrint"
                                                            onError={() => setIsErrImg(true)}

                                                        />
                                                }
                                                <div className="content">
                                                    <p className="value">{details?.profileId || "-"}</p>
                                                    <p className="text">Profile ID</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcUserBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{details?.fullName || "-"}</p>
                                                    <p className="text">Họ tên</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcPhoneBlueDefault className="icon" />
                                                <div className="content">
                                                    <p className="value">{details?.phone || "-"}</p>
                                                    <p className="text">Số điện thoại</p>
                                                </div>
                                            </div>
                                            <div className="info">
                                                <IcEmailBgBlue className="icon" />
                                                <div className="content">
                                                    <p className="value">{details?.email?.email || "-"}</p>
                                                    <p className="text">Email</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <EmptyDataControl
                                            icon={<IcSearchDefault />}
                                            text={"Không tìm được thông tin khách hàng"}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="info-face-registration">
                            <div className="wrap-value">
                                <div className="wrap-value-note">
                                    <IcNoteBlue className="ic-note" onClick={() => setIsShowPopupNote(true)} />
                                    <p className="note">Ghi chú</p>
                                </div>
                                {isShowPopupNote &&
                                <PopupAttachNote 
                                    isStylePopup={true}
                                    onClickClosePopup={() => setIsShowPopupNote(false)}
                                    data={data}
                                    isShowContentPopup={POPUP_NOTE_TRANSACTION_CUSTOMER}
                                />}
                            </div>
                            <div className="face-registration">
                                <div className="wrap-media face-img" onClick={_handleZoomPhoto}>
                                    {
                                        isErrCustomerPhoto ?
                                            <div className="wrapper">
                                                <IcTransPhotoDefault />
                                            </div>
                                            :
                                            <img
                                                src={thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : ""}
                                                alt="face registration"
                                                onError={() => setIsErrCustomerPhoto(true)}
                                            />
                                    }
                                    {/* <div className="tools">
                                        <IcZoomIn />
                                    </div> */}
                                </div>
                                <div className="wrap-media recog-video" onClick={_handleZoomVideo}>
                                    {
                                        isErrCustomerVideo ?
                                            <div className="wrapper">
                                                <IcTransVerifyVideo />
                                            </div>
                                            :
                                            <>
                                                <img
                                                    src={thumbnailId ? apiGetBankCustomerAvatar(thumbnailId) : ""}
                                                    alt="video registration"
                                                    onError={() => setIsErrCustomerVideo(true)}
                                                />
                                                <div className="overlay">
                                                    <IcPlayCircle />
                                                </div>
                                            </>
                                    }
                                </div>
                                {
                                    !!showMedia &&
                                    <Portal>
                                        <PopupShowMedia
                                            mediaType={showMedia.type}
                                            url={showMedia.url}
                                            closePopup={() => setShowMedia(null)}
                                        />
                                    </Portal>
                                }
                            </div>
                            <div className="wrap-value">
                                <p>Image Proof:</p>
                                <p className="value-bioId" title={bioId}>{bioId || "-"}</p>
                            </div>
                        </div>
                    </div>
                </WrapCustomerTransHistoryDetailsItem>
            </div>
            {
                isShowPopupComfirmRefund ? 
                <PopupConfirmApprove 
                    detailsTrans={detailsTrans}
                    dataTransacion={data}
                    onClickClosePopupComfirm={() => setIsShowPopupComfirmRefund(false)}
                /> : ""
            }
        </OverlayFullScreen>
    )
}

export default CustomerTransDetail
