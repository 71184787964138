import React, { useRef, useState } from 'react'

// hooks
import { useOnClickOutside } from '../../../hook/useClickOutside';

// component
import DropdownList from '../DropdownList';
import IcArrowDownGray from '../../../assets/images/IcArrowDownGray';

// styles
import { WrapDropdownControl } from './index.styles'

const DropdownControl = ({
    className,
    dataSelection,
    valueSelected: { text, key, icon },
    handleSetValueSelect,
    maxItemShow,
    alignLeft,
    maxWidth,
    isShowIcon,
    fitContent
}) => {
    
    const refTitleDropdown = useRef(null)
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const _handleSetValueSelect = (val) => {
        handleSetValueSelect(val)
        setIsShowDropdown(false)
    }

    useOnClickOutside(refTitleDropdown, () => setIsShowDropdown(false))

    return (
        <WrapDropdownControl ref={refTitleDropdown} className={className} fitContent={fitContent}>
            <div className="wrap-title-dropdown" onClick={() => setIsShowDropdown(pre => !pre)}>
                <span className="value">{text}</span>
                <IcArrowDownGray className="arrow-down" />
            </div>
            {
                isShowDropdown &&
                <DropdownList 
                    valueSelected={{ text, key, icon }}
                    listItem={dataSelection}
                    setValueSelect={_handleSetValueSelect}
                    isShowCheckStatus={true}
                    maxItemShow={maxItemShow}
                    alignLeft={alignLeft}
                    maxWidth={maxWidth}
                    isShowIcon={isShowIcon}
                />
            }
        </WrapDropdownControl>
    )
}

export default DropdownControl
