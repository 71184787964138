import React from "react";
import { WrapInfoKycCMND } from "./index.styled";

// image
import IcInfoCustom from '../../../../../../../../../assets/images/IcInfoCustom'
import IcUserBlueDefault from '../../../../../../../../../assets/images/IcUserBlueDefault'
import IcCalendaDefault from '../../../../../../../../../assets/images/IcCalendaDefault'
import IcLocationDefault from '../../../../../../../../../assets/images/IcLocationDefault'
import IcSexDefault from '../../../../../../../../../assets/images/IcSexDefault'

// data
import { dataGender } from "../../../../../../../../../data/dataCustomer";

const InfoKycCMND = ({ item, isCheckstyle }) => {

  return (
    <WrapInfoKycCMND className={isCheckstyle ? "style-popup-eKYC" : ""} isCheckstyle={isCheckstyle}>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.id || "-"}</div>
          <div className="text">Số giấy tờ</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.issue_date || "-" }</div>
          <div className="text">Ngày cấp</div>
        </div>
      </div>
      <div className="info-item">
        <IcUserBlueDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.name || "-"}</div>
          <div className="text">Họ tên</div>
        </div>
      </div>
      <div className="info-item">
        <IcInfoCustom />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.issued_at || "-"}</div>
          <div className="text">Nơi cấp</div>
        </div>
      </div>
      <div className="info-item">
        <IcCalendaDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.dob || "-"}</div>
          <div className="text">Ngày sinh</div>
        </div>
      </div>
      <div className="info-item">
        <IcLocationDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.hometown || "-"}</div>
          <div className="text">Nguyên quán</div>
        </div>
      </div>
      <div className="info-item">
        <IcSexDefault />
        <div className="info-value">
          <div className="value">{dataGender[item.ekycInfoId.gender.toUpperCase()]?.text || "-"}</div>
          <div className="text">Giới tính</div>
        </div>
      </div>
      <div className="info-item">
        <IcLocationDefault />
        <div className="info-value">
          <div className="value">{item.ekycInfoId.address || "-"}</div>
          <div className="text">Địa chỉ thường trú</div>
        </div>
      </div>
    </WrapInfoKycCMND>
  );
};

export default InfoKycCMND;
