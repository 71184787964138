import React from 'react'
import { BANK } from '../../../../../../../../../constant/keys'
import { dataBankType } from '../../../../../../../../../data/dataBankType'

//component
import DropdownMultiChoise from '../../../../../../../../Control/DropdownMultiChoise'
import { FACEPAYTERMINAL, WALLET_FACEPAY } from '../../../../../TransactionV2'

const labelCheck = 'ngân hàng'

const InputDropdownBank = ({
    reqType,
    className,
    iconCheckBox,
    listDropdown,
    listChecked,
    handleCheckItem
}) => {
    
    const value = (() => {
        if (!listChecked?.length){
            return `0 ${reqType === FACEPAYTERMINAL ? "phương thức" : reqType === WALLET_FACEPAY ? "giao dịch" : labelCheck}`
        }

        if (listChecked.length >= 1 && listChecked?.length < listDropdown.length) {
            return (
                <div className="label-check">{listChecked?.length} {reqType === FACEPAYTERMINAL ? "phương thức" : reqType === WALLET_FACEPAY ? "giao dịch" : labelCheck}</div>
            )
        }
        return (
            <div className="label-check">Tất cả</div>
        )
    })()

    return (
        <DropdownMultiChoise
            className={className}
            iconCheckBox={iconCheckBox}
            list={listDropdown}
            value={value}
            // listChecked={listChecked}
            // labelCheck={labelCheck}
            handleCheckItem={handleCheckItem}
            reqType={reqType}
        />
    )
}

export default InputDropdownBank
