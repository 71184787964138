import React from 'react'

import { CUSTOMER, TRANSACTION } from '../../../../../../../../../constant/path'
import CustomerTransDetail from './CustomerTransDetail'
import TransactionDetail from './TransactionDetail'


const TransHistoryDetailsItem = ({ data, reqType, onClickClosePopup, detailsTrans, dataTransacion, logoMerchent }) => {

    return (
        <>
            {
                detailsTrans === CUSTOMER
                    ?
                    <CustomerTransDetail detailsTrans={detailsTrans} data={data} onClickClosePopup={onClickClosePopup} />
                    :
                    <TransactionDetail reqType={reqType} logoMerchent={logoMerchent} dataTransacion={dataTransacion} onClickClosePopup={onClickClosePopup} detailsTrans={detailsTrans}/>
            }
        </>
    )
}

export default TransHistoryDetailsItem
