import React, { useRef } from 'react'

// component
import IcCheckBlue from '../../../assets/images/IcCheckBlue'

// hook
import useScrollEnd from '../../../hook/useScrollEnd'

// styles
import { WrapDropdownList } from './index.styles'

const DropdownList = ({
    isShowCheckStatus = false,
    listItem,
    valueSelected,
    setValueSelect,
    alignTop,
    alignLeft,
    className,
    maxItemShow,
    handleScrollEnd,
    isShowIcon
}) => {
    const eleRef = useRef()
    
    useScrollEnd(eleRef, (callback) => {
        if (!maxItemShow) return;
        if (!handleScrollEnd) return;
        handleScrollEnd(callback)
    }, [handleScrollEnd])

    return (
        <WrapDropdownList ref={eleRef} alignTop={alignTop} alignLeft={alignLeft} className={className} maxItemShow={maxItemShow}>
            {
                listItem.map((item) => {
                    const { text, key, icon } = item
                    return <div key={key} onClick={() => setValueSelect(item)}>
                        {isShowIcon && icon}
                        <span title={text}>{text}</span>
                        {isShowCheckStatus && valueSelected?.key === key && <IcCheckBlue />}
                    </div>
                })
            }
        </WrapDropdownList>
    )
}

export default DropdownList
