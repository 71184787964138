import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapBankCustomerList, TableContent } from './index.styles'

//image
import IcCensorshipDefault from '../../../../../../../assets/images/IcCensorshipDefault'

//component
import EmptyDataControl from '../../../../../../Control/EmptyDataControl'
import CustomerItem from '../CustomerItem'
import FilterBankBlock from './FilterBankBlock'

//action
import { getListCustomerBank, resetFacepayCustomerCondsFilter, resetListCustomerFacepay, updateBankCustomerCondsFilter } from '../../../../../../../redux/customer/listCustomer/actionV2'
import useScrollEnd from '../../../../../../../hook/useScrollEnd'
import { clearDetailCustomer, getDetailCustomer, resetListDeviceHistoryCustomer, resetListEkycHistoryCustomer, resetListVerifyHistoryCustomer } from '../../../../../../../redux/customer/customerDetail/action'
import { LOADING_GET_LIST_CUSTOMER } from '../../../../../../../redux/customer/listCustomer/types'
import LoadingLocal from '../../../../../../Control/LoadingLocal'
import { listProviders } from '../../../../../../../redux/customer/listCustomer/listCustomerReducerV2'
import { LOADING_GET_LIST_EKYC_CUSTOMER, LOADING_GET_LIST_DEVICE_CUSTOMER, LOADING_GET_LIST_VERIFY_CUSTOMER } from '../../../../../../../redux/customer/customerDetail/types'
import { resetListTransactionBankOfCustomer, resetListTransactionFacepayOfCustomer, resetTransactionOfCustomerCondsFilter } from '../../../../../../../redux/customer/customerTransactionHistory/actionV2'
import { resetListCustomerFacepayBacklog, resetListCustomerBankBacklog, resetUpdateListBacklogCustomerCondsFilter } from '../../../../../../../redux/customer/customerBacklog/action'
import { resetListCustomerBankNote, resetListCustomerNote } from '../../../../../../../redux/customer/customerNote/action'
import { resetCustomerAnalytic, resetCustomerBankAnalytic } from '../../../../../../../redux/customer/customerAnalytic/action'
import { LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER } from '../../../../../../../redux/customer/customerTransactionHistory/types'
import { LOADING_GET_LIST_BACKLOG_CUSTOMER } from '../../../../../../../redux/customer/customerBacklog/types'
import resetListFilterBank from '../../../../../../../helper/resetListFilterBank'

const BankCustomerList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const [keySelected, setKeySelected] = useState("")

    const { listCustomerBank, isCheckList, condFiltersBank, isScroll, isTypeLoading, isTypeLoadingVerify, isTypeLoadingDevice, isTypeLoadingEkyc, isTypeLoadingTransaction, isTypeLoadingBacklog } = useSelector(state => ({
        listCustomerBank: state.customerReducer.listCustomerReducer.listCustomerBank,
        isCheckList: state.customerReducer.listCustomerReducer.isCheckList,

        condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,
        isScroll: state.customerReducer.listCustomerReducer.isScroll,

        isTypeLoading: state.loading[LOADING_GET_LIST_CUSTOMER],
        isTypeLoadingVerify: state.loading[LOADING_GET_LIST_VERIFY_CUSTOMER],
        isTypeLoadingDevice: state.loading[LOADING_GET_LIST_DEVICE_CUSTOMER],
        isTypeLoadingEkyc: state.loading[LOADING_GET_LIST_EKYC_CUSTOMER],
        isTypeLoadingTransaction: state.loading[LOADING_GET_LIST_TRANSACTION_OF_CUSTOMER],
        isTypeLoadingBacklog: state.loading[LOADING_GET_LIST_BACKLOG_CUSTOMER],

    }))

    const userSelected = listCustomerBank.filter(item => item.id === keySelected)    

    useEffect(() => {
        if (!keySelected || userSelected.length === 0) {
            setKeySelected(listCustomerBank[0]?.id)
            dispatch(getDetailCustomer(listCustomerBank[0]))
            return
        }
        if (keySelected) {
            setKeySelected(keySelected)
            dispatch(getDetailCustomer(userSelected[0]))
            return
        }
    }, [listCustomerBank])

    useEffect(() => {
        dispatch(resetFacepayCustomerCondsFilter())
        dispatch(resetListCustomerFacepay())
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListTransactionBankOfCustomer())
        dispatch(resetListTransactionFacepayOfCustomer())
        dispatch(resetTransactionOfCustomerCondsFilter())
        dispatch(resetListCustomerFacepayBacklog())
        dispatch(resetListCustomerBankBacklog())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
        dispatch(resetListCustomerNote())
        dispatch(resetListCustomerBankNote())
        dispatch(resetCustomerAnalytic())
    }, [])

    useEffect(() => {
        dispatch(resetListVerifyHistoryCustomer())
        dispatch(resetListEkycHistoryCustomer())
        dispatch(resetListDeviceHistoryCustomer())
        dispatch(resetListTransactionBankOfCustomer())
        dispatch(resetListTransactionFacepayOfCustomer())
        dispatch(resetTransactionOfCustomerCondsFilter())
        dispatch(resetListCustomerFacepayBacklog())
        dispatch(resetListCustomerBankBacklog())
        dispatch(resetUpdateListBacklogCustomerCondsFilter())
        dispatch(resetListCustomerNote())
        dispatch(resetListCustomerBankNote())
        dispatch(resetCustomerBankAnalytic())
    }, [keySelected])

    useEffect(() => {
        dispatch(getListCustomerBank({
            limit: condFiltersBank.limit,
            skipItem: condFiltersBank.skipItem,
            phone: condFiltersBank.phone,
            providers: condFiltersBank.providers.filter((item) => item.isSelect).map((item) => item.type)
        }, isScroll))
        return () => {

        }
    }, [JSON.stringify(condFiltersBank)])

    useEffect(() => {
        if (listCustomerBank.length === 0 && !isCheckList) {
            dispatch(getListCustomerBank({
                limit: condFiltersBank.limit,
                skipItem: condFiltersBank.skipItem,
                phone: condFiltersBank.phone,
                providers: resetListFilterBank(listProviders)
            }, isScroll))
            setKeySelected(listCustomerBank[0]?.id)
        }
    }, [listCustomerBank])

    useScrollEnd(listRef, (callback) => {
        dispatch(updateBankCustomerCondsFilter({
            condFiltersBank: {
                limit: 15,
                skipItem: listCustomerBank.length,
            },
            isScroll: true,
            callbackSuccess: callback
        }))
    }, [listCustomerBank], [listCustomerBank.length])

    const _handleSelectActive = (item) => {
        if (!item) return
        if (isTypeLoadingVerify || isTypeLoadingDevice || isTypeLoadingEkyc || isTypeLoadingTransaction || isTypeLoadingBacklog) return
        setKeySelected(item.id)
        const callbackSuccess = () => {
            dispatch(getDetailCustomer(item))
        }
        dispatch(clearDetailCustomer(callbackSuccess))
    }

    return (
        <WrapBankCustomerList>
            <FilterBankBlock />
            {
                listCustomerBank?.length === 0 && isTypeLoading ?
                    <LoadingLocal />
                    :
                    <>
                        {
                            listCustomerBank?.length === 0 ? (
                                <div className="wrap-empty">
                                    <EmptyDataControl
                                        icon={<IcCensorshipDefault />}
                                        text={"Hiện tại chưa có khách hàng mới"}
                                    />
                                </div>
                            ) : (
                                    <TableContent>
                                        <div className="wrap-list-customer" ref={listRef}>
                                            <div className="list-customer">
                                                {listCustomerBank?.map((item, index) => (
                                                    <div className="list" key={index}
                                                        onClick={() => _handleSelectActive(item)}>
                                                        <CustomerItem data={item} keySelected={keySelected} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </TableContent>
                                )
                        }
                    </>
            }
        </WrapBankCustomerList>
    )
}

export default BankCustomerList
