import React, { useState, useRef, useEffect } from 'react'

//style
import { WrapDropdownMultiChoice } from './index.styles'

//image
import IcArrowDownGray from '../../../assets/images/IcArrowDownGray';

//component
import CheckBoxControl from '../CheckBoxControl';
import { useOnClickOutside } from '../../../hook/useClickOutside';
import { dataBankType } from '../../../data/dataBankType';
import { useDispatch, useSelector } from 'react-redux';
import { updatecondCheckboxAll } from '../../../redux/transactionV2/transactionOther/action';
import { FACEPAYTERMINAL, WALLET_FACEPAY } from '../../Main/Content/Page/TransactionV2';
import { updateCondfiterTransactionFacepayWallet } from '../../../redux/transactionV2/listTransactionWalletFacepay/action';
import { BANK, BANK_CUSTOMER } from '../../../constant/keys';
import { updateListTransactionBank } from '../../../redux/transactionV2/listTransactionBank/action';
import { updateCondFiltersTransactionFacepay } from '../../../redux/transactionV2/listTransactionFacepayTerminal/action';
import { updateBankCustomerCondsFilter } from '../../../redux/customer/listCustomer/actionV2';
import { listProviders } from '../../../redux/customer/listCustomer/listCustomerReducerV2';

const DropdownMultiChoise = ({
    className,
    iconCheckBox,
    listChecked,
    // labelCheck,
    value,
    list,
    handleCheckItem,
    reqType
}) => {

    const dropdownRef = useRef()
    const wrapDropdownRef = useRef()
    const dispatch = useDispatch()

    const [dataLocal, setDataLocal] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const { condCheckboxAll, condFiltersTransactionFacepayWallet, condFiltersTransactionBank, condFiltersTransactionFacepay, condFiltersBank } = useSelector((state) => ({
        condCheckboxAll: state.transactionReducer.transactionOtherReducer.condCheckboxAll,

        // WALLET FACEPAY
        condFiltersTransactionFacepayWallet: state.transactionReducer.listTransactionFacepayWalletReducer.condFiltersTransactionFacepayWallet,

        // BANK 
        condFiltersTransactionBank: state.transactionReducer.listTransactionBankReducer.condFiltersTransactionBank,

        // FACEPAY TERMINAL
        condFiltersTransactionFacepay: state.transactionReducer.listTransactionFacepayReducer.condFiltersTransactionFacepay,

        // BANK CUSTOMER
        condFiltersBank: state.customerReducer.listCustomerReducer.condFiltersBank,

    }))

    useEffect(() => {
        setDataLocal(list)
    }, [list])

    useOnClickOutside(dropdownRef, () => {
        setIsShowDropdown(false)
    })

    const handleCheckAll = () => {

        if(reqType === WALLET_FACEPAY){
            dispatch(updatecondCheckboxAll({
                ...condCheckboxAll,
                isSelect: !condCheckboxAll.isSelect
            }))
            dispatch(updateCondfiterTransactionFacepayWallet({
                ...condFiltersTransactionFacepayWallet,
                OptionSelectCheckboxTypeTransaction: condFiltersTransactionFacepayWallet.OptionSelectCheckboxTypeTransaction.map((item) => {
                    return {
                        ...item,
                        isSelect: !condCheckboxAll.isSelect
                    }
                })
            }))
            return
        }
        if(reqType === BANK){
            dispatch(updatecondCheckboxAll({
                ...condCheckboxAll,
                isSelect: !condCheckboxAll.isSelect
            }))
            dispatch(updateListTransactionBank({
                ...condFiltersTransactionBank,
                supplier: condFiltersTransactionBank.supplier.map((item) => {
                    return {
                        ...item,
                        isSelect: !condCheckboxAll.isSelect
                    }
                })
            }))
            return
        }
        if(reqType === FACEPAYTERMINAL){
            dispatch(updatecondCheckboxAll({
                ...condCheckboxAll,
                isSelect: !condCheckboxAll.isSelect
            }))
            dispatch(updateCondFiltersTransactionFacepay({
                ...condFiltersTransactionFacepay,
                supplier: condFiltersTransactionFacepay.supplier.map((item) => {
                    return {
                        ...item,
                        isSelect: !condCheckboxAll.isSelect
                    }
                })
            }))
            return
        }

        if(reqType === BANK_CUSTOMER){
            dispatch(updatecondCheckboxAll({
                ...condCheckboxAll,
                isSelect: !condCheckboxAll.isSelect
            }))
            dispatch(updateBankCustomerCondsFilter({
                condFiltersBank: {
                    ...condFiltersBank,
                    providers: condFiltersBank.providers.map((item) => {
                        return {
                            ...item,
                            isSelect: !condCheckboxAll.isSelect
                        }
                    })
                }
            }))
            return
        }
    }
    return (
        <WrapDropdownMultiChoice ref={dropdownRef} className={className}>
            <div className="combo-box" onClick={() => setIsShowDropdown(pre => !pre)}>
                <div className="value" title={value}>{value}</div>
                <IcArrowDownGray className="icon" />
            </div>
            {
                isShowDropdown &&
                <div className="wrap-dropdown" ref={wrapDropdownRef}>
                    <div className="wrap-selection">
                    <CheckBoxControl
                        iconCheckBox={iconCheckBox}
                        className="checkbox"
                        isChecked={condCheckboxAll.isSelect}
                        handleCheck={handleCheckAll}
                        title={condCheckboxAll.title} />
                        {
                            dataLocal.length !== 0 &&
                            dataLocal.map((item, index) => {
                                const { title, isSelect} = item
                                return (
                                    <CheckBoxControl
                                        key={index}
                                        iconCheckBox={iconCheckBox}
                                        className="checkbox"
                                        isChecked={isSelect}
                                        handleCheck={() => handleCheckItem(index)}
                                        title={title}>
                                        {title}
                                    </CheckBoxControl>
                                )
                            })
                        }
                    </div>
                </div>
            }

        </WrapDropdownMultiChoice>
    )
}

export default DropdownMultiChoise
